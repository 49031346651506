<template>
<div class="modal fade " id="SurChargeDetailsModal" tabindex="-1" role="dialog" aria-labelledby="SurChargeDetailsModal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-md modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header bg-primary">
                <h5 class="text-white mb-0 pb-0">Surcharge </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <i class="bx bx-x"></i>
                </button>
            </div>
            <div class="modal-body">
                <div class="row" v-if="timebase_surcharge.applied_status">
                    <div class="col-7 text-left">
                        <p class="text-bold-400">Time-base surcharge:</p>
                    </div>
                    <div class="col-5 text-right">
                        <p>${{ parseFloat(timebase_surcharge.amount /100 ).toFixed(2) }} </p>
                    </div>
                </div>
                <div class="row" v-if="weekend_surcharge.applied_status">
                    <div class="col-7 text-left">
                        <p class="text-bold-400">Weekend Surcharge:</p>
                    </div>
                    <div class="col-5 text-right">
                        <p class="">${{ parseFloat(weekend_surcharge.amount /100 ).toFixed(2) }}</p>
                    </div>
                </div>
                <div class="row" v-if="holiday_surcharge.applied_status">
                    <div class="col-7 text-left">
                        <p class="text-bold-400">Holiday Surcharge:</p>
                    </div>
                    <div class="col-5 text-right">
                        <p class="">${{ parseFloat(holiday_surcharge.amount /100).toFixed(2) }}</p>
                    </div>
                </div>
                <div class="row" v-if="fuel_levy.applied_status">
                    <div class="col-7 text-left">
                        <p class="text-bold-400">Fuel Levy :</p>
                    </div>
                    <div class="col-5 text-right">
                        <p class="">${{ parseFloat(fuel_levy.amount /100).toFixed(2) }}</p>
                    </div>
                </div>
                <div class="row" v-if="city_area_surcharge.applied_status">
                    <div class="col-7 text-left">
                        <p class="text-bold-400">City Area Surcharge:</p>
                    </div>
                    <div class="col-5 text-right">
                        <p class="">${{ parseFloat(city_area_surcharge.amount /100).toFixed(2) }}</p>
                    </div>
                </div>
                <div class="row" v-if="distance_surcharge.applied_status">
                    <div class="col-7 text-left">
                        <p class="text-bold-400">Distance Surcharge ({{distance_surcharge.distance_value_in_km}}) : </p>
                    </div>
                    <div class="col-5 text-right">
                        <p class="">${{parseFloat(distance_surcharge.amount /100 ).toFixed(2)}} </p>
                    </div>
                </div>
                <div class="row" v-if="same_day_surcharge.applied_status">
                    <div class="col-7 text-left">
                        <p class="text-bold-400">Sameday Fee:</p>
                    </div>
                    <div class="col-5 text-right">
                        <p class="">${{ parseFloat(same_day_surcharge.amount /100 ).toFixed(2) }} </p>
                    </div>
                </div>
                <hr>
                 <div class="row">
                    <div class="col-7 text-left">
                        <p class="text-bold-700">Total Surcharge:</p>
                    </div>
                    <div class="col-5 text-right">
                        <p class="text-bold-700">${{ parseFloat(total_surcharge_amount / 100).toFixed(2) }} </p>
                    </div>
                </div>
                
            </div>

        </div>
    </div>
</div>
</template>

<script>
export default {
    name: "SurChargeDetailsModal",
    props: ['preAppointmentResponse'],
    data() {
        return {

            same_day_surcharge: {
                applied_status: '',
                amount: ''
            },
            timebase_surcharge: {
                applied_status: '',
                amount: '',
            },
            weekend_surcharge: {
                applied_status: '',
                amount: ''
            },
            distance_surcharge: {
                applied_status: '',
                message: '',
                distance_value: '',
                distance_value_in_km: '',
                amount: '',
            },
            holiday_surcharge: {
                applied_status: '',
                amount: '',
            },
            fuel_levy: {
                applied_status: '',
                amount: '',
            },
            city_area_surcharge: {
                applied_status: '',
                amount: '',
            },
            total_surcharge_amount: '',

            grand_total: '',
        }
    },
    watch: {
        preAppointmentResponse(surchargeData) {
          

            this.same_day_surcharge.applied_status = surchargeData.same_day_surcharge.applied_status;
            this.same_day_surcharge.amount = surchargeData.same_day_surcharge.amount;

            this.timebase_surcharge.applied_status = surchargeData.timebase_surcharge.applied_status;
            this.timebase_surcharge.amount = surchargeData.timebase_surcharge.amount;

            this.distance_surcharge.applied_status = surchargeData.distance_surcharge.applied_status;
            this.distance_surcharge.distance_value = surchargeData.distance_surcharge.distance_value;
            this.distance_surcharge.distance_value_in_km = surchargeData.distance_surcharge.distance_value_in_km;
            this.distance_surcharge.amount = surchargeData.distance_surcharge.amount;

            this.holiday_surcharge.applied_status = surchargeData.holiday_surcharge.applied_status;
            this.holiday_surcharge.amount = surchargeData.holiday_surcharge.amount;

            this.fuel_levy.applied_status = surchargeData.fuel_levy.applied_status;
            this.fuel_levy.amount = surchargeData.fuel_levy.amount;

            this.city_area_surcharge.applied_status = surchargeData.city_area_surcharge.applied_status;
            this.city_area_surcharge.amount = surchargeData.city_area_surcharge.amount;

            this.weekend_surcharge.amount = surchargeData.weekend_surcharge.amount;
            this.weekend_surcharge.applied_status = surchargeData.weekend_surcharge.applied_status;

            this.total_surcharge_amount = surchargeData.total_surcharge_amount;

            this.grand_total = surchargeData.grand_total;
        }
    },

}
</script>

<style>

</style>
