<template>
<div class="modal fade " id="GetAvailableTechniciansResponse" tabindex="-1" role="dialog" aria-labelledby="GetAvailableTechniciansResponse" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header bg-primary">
                <h1></h1>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <i class="bx bx-x"></i>
                </button>
            </div>
            <div class="modal-body">
                <div class="text-center">
                    <i class='bx bxs-error bx-flip-horizontal' :style="{color:'#5a8dee',fontSize:'80px'}"></i>
                </div>
                <div class="text-center">
                    <h3 class="text-dark pt-1"></h3>
                    <div >
                             <h3  class="text-danger text-bold-300" >{{ errorMessage }}</h3> 
                    </div>
                </div>
            </div>
            <div class="modal-footer border-0 py-1">
                <button type="button" class="btn btn-primary" data-dismiss="modal">
                    <i class="bx bx-x d-block d-sm-none"></i>
                    <span class="d-none d-sm-block">Close</span>
                </button>

            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: "GetAvailableTechniciansResponse",
    props:['getAvailableTechniciansResponse'],
    data() {
        return {
            errorMessage : ""
        }   
    },
    watch: {
        getAvailableTechniciansResponse(currentError){
            this.errorMessage = currentError
        }
    },

}
</script>

<style>

</style>
