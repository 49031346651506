<template>
  <AppLayout>
    <template v-slot:appContent>

      <div class="d-sm-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0 ">
        <h4 class="">Appointments/Create</h4>
        <div>
          <router-link :to="{...previousRoute}">
            <span class="glow d-flex align-items-center"><i class='bx bx-undo bx-flashing'></i>&nbsp;Back</span>
          </router-link>
        </div>
      </div>
      <div class="row">
        <div class="col-12 mx-auto">
          <div class="">
            <div class="pb-5">

              <!-- customer information for existing customer -->
              <div class="card" v-if="step === 1">
                <div class="card-body">
                  <div class="progress mb-3 mx-auto">
                    <div class="progress-bar progress-bar-striped active bg-primary" role="progressbar"
                         style="width: 12.5%;" aria-valuenow="12.5" aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                  <div class="">
                    <div class="text-center mb-2">
                      <h4 class="mx-auto text-uppercase mb-0 pb-0">
                        <span class="text-uppercase text-bold-500">Customer Information</span>
                      </h4>
                    </div>
                    <h5 class="text-center mb-2 mb-0 pb-0"><small class="text-primary">Please make a selection</small>
                    </h5>
                    <div class="d-flex justify-content-center">
                      <div class="mr-1 d-flex " v-if="can('customer-view')">
                        <input type="radio" value="existing" name="customerType"
                               style="opacity: 0; visibility: hidden; display: none;" v-model="customerType"
                               id="existing">
                        <label for="existing"
                               class="text-white service-type-container d-flex align-items-center justify-content-center"
                               :class="[customerType == 'existing' ? 'bg-primary text-white' : 'bg-white text-dark']"
                               :style="[customerType == 'existing' ? 'border: 2px solid transparent' : 'border: 2px solid #858585;']">
                          <span class="mr-1 d-none d-md-block"><i class='bx bx-user-minus'></i></span>
                          <span class="mb-0 font-weight-bold service-type">Existing</span>
                        </label>
                      </div>
                      <div class="ml-1 " v-if="can('customer-create')">
                        <input type="radio" value="new" name="customerType"
                               style="opacity: 0; visibility: hidden;display: none;" @click="createNewCustomer"
                               v-model="customerType" id="new">
                        <label for="new"
                               class="text-white service-type-container d-flex align-items-center justify-content-center"
                               :class="[customerType == 'new' ? 'bg-primary text-white' : 'bg-white text-dark']"
                               :style="[customerType == 'new' ? 'border: 2px solid transparent' : 'border: 2px solid #858585;']">
                          <span class="mr-1 d-none d-md-block"><i class='bx bx-user-plus'></i></span>
                          <span class="mb-0 font-weight-bold service-type">New</span>
                        </label>
                      </div>
                    </div>
                    <!-- showing error -->
                    <div v-if="frontEndErrors.customerType.length > 0" class="text-center pt-2">
                      <p class="text-danger mb-0 font-medium-1" v-for="(error, index) in frontEndErrors.customerType"
                         :key="index">{{ error }}</p>
                    </div>
                    <div class="py-2">
                      <div v-if="customerType === 'existing'">
                        <div class="row d-flex justify-content-center">
                          <!-- <div class="col-md-6 col-12">
                                  <div class="form-group position-relative">
                                  <div class="form-group position-relative">
                                      <label>Search customer</label>
                                      <input v-model="customersSearchKey" @input="searchCustomers" type="text" class="form-control " placeholder="Search customer...">
                                      <div v-if="customers.length > 0" class="search-result-list">
                                          <div v-for="(customer,index) in customers" :key="index">
                                              <p @click="getCustomerInformation(customer.id)" class="mb-0 customer-list-item"> <span>{{ customer.user.first_name }}</span> | <span>{{ `${customer.user.email ? customer.user.email+' | ' :''}` }}</span> <span>{{ customer.user.phone_number }}</span></p>
                                          </div>
                                      </div>

                                  </div>
                              </div> -->

                          <div class="col-md-6 col-12">
                            <div class="form-group position-relative">
                              <label>Search customer</label>
                              <VueMultiselect v-model="selectedSearchedCustomer" placeholder="Type to search"
                                              label="name" track-by="name" id="ajax" open-direction="bottom"
                                              :options="customerOptions" :searchable="true" :loading="isLoading"
                                              :options-limit="300" :limit="3" :show-no-results="true"
                                              :hide-selected="true" @search-change="findCustomer"/>

                            </div>
                          </div>

                        </div>
                        <!-- showing error if user do not search customer -->
                        <div v-if="frontEndErrors.customerSearch.length > 0" class="text-center pt-2">
                          <p class="text-danger mb-0 font-medium-1"
                             v-for="(error, index) in frontEndErrors.customerSearch" :key="index">{{ error }}</p>
                        </div>
                        <div v-if="user.id" class="row d-flex justify-content-center">
                          <div class="col-md-6 col-12">
                            <p v-if="user.firstName"><span class="text-bold-600">First Name :</span>
                              {{ user.firstName }}</p>
                            <p v-if="user.lastName"><span class="text-bold-600">Last Name :</span> {{ user.lastName }}
                            </p>
                            <p v-if="user.email"><span class="text-bold-600">Email :</span> {{ user.email }}</p>
                            <p v-if="user.phoneNumber"><span class="text-bold-600">Phone :</span> {{ user.phoneNumber }}
                            </p>
                            <p v-if="address.street"><span class="text-bold-600">Billing Address : </span>
                              {{ userFullAddress }} <br><span data-toggle="modal" data-target="#addressUpdateModal"
                                                          class="text-primary cursor-pointer"> ( Update address <i
                                  class='bx bx-edit-alt'></i> )</span></p>
                            <p v-if="address.street"><span class="text-bold-600">Service Address : </span>
                              {{ serviceFullAddress }} <br><span data-toggle="modal" data-target="#userAddressCreateModal"
                                                             class="text-primary cursor-pointer"> ( Update address <i
                                  class='bx bx-edit-alt'></i> )</span></p>
                          </div>
                        </div>
                        <!-- <div v-if="address.id && !existingCustomerBusiness.businessId && appointmentType == 'business'">
                                <p class="text-muted font-weight-bold">Business Information</p>
                                <div class="row">
                                    <div class="col-12 col-md-6">
                                        <div class="form-group">
                                            <label>Business Name</label>
                                            <input v-model="existingCustomerBusiness.businessName" type="text" class="form-control" aria-describedby="" placeholder="Enter business name">

                                            <div v-if="customerBackEndErrors.name && customerBackEndErrors.name.length > 0 " class="text-danger">
                                                <p class="mb-0" v-for="(error,index) in customerBackEndErrors.name" :key="index"> {{ error }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                      </div>
                      <div v-if="customerType === 'new'">

                      </div>
                    </div>
                    <div class="d-flex justify-content-end mt-3 pt-2 px-0 px-md-4">

                      <div class="">
                        <button @click="customerTypeStepNext" class="btn border-primary text-primary font-weight-bold">
                          <span class="d-none d-md-inline-block">Next</span> <span><i
                            class='bx bx-chevron-right'></i></span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- service type -->
              <div class="card" v-if="step === 2">
                <div class="card-body">
                  <div class="progress mb-3 mx-auto">
                    <div class="progress-bar progress-bar-striped active bg-primary" role="progressbar"
                         style="width: 25%;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                  <div class="row pb-4 d-flex justify-content-center">
                    <div class="col-md-4 col-12 mb-lg-0 mb-4">
                      <div class="">
                        <div class="text-center mb-2">
                          <h4 class="mx-auto text-uppercase mb-0 pb-0">
                            <span class="text-uppercase text-bold-500">Service Preference</span>
                          </h4>
                        </div>
                        <h5 class="text-center mb-2 mb-0 pb-0">What type of service do you need <small
                            class="text-primary">(Please make a selection)</small> ?</h5>
                        <div class="d-flex justify-content-center">
                          <div class="mr-1 d-flex ">
                            <input type="radio" value="remote" name="serviceType"
                                   style="opacity: 0; visibility: hidden; display: none;" v-model="serviceType"
                                   id="remote">
                            <label for="remote"
                                   class="text-white service-type-container d-flex align-items-center justify-content-center"
                                   :class="[serviceType == 'remote' ? 'bg-primary text-white' : 'bg-white text-dark']"
                                   :style="[serviceType == 'remote' ? 'border: 2px solid transparent' : 'border: 2px solid #858585;']">
                              <span class="mr-1 d-none d-md-block"><i class='bx bx-sitemap'></i></span>
                              <span class="mb-0 font-weight-bold service-type">Remote</span>
                            </label>
                          </div>
                          <div class="ml-1 ">
                            <input type="radio" value="onsite" name="serviceType"
                                   style="opacity: 0; visibility: hidden;display: none;" v-model="serviceType"
                                   id="onsite">
                            <label for="onsite"
                                   class="text-white service-type-container d-flex align-items-center justify-content-center"
                                   :class="[serviceType == 'onsite' ? 'bg-primary text-white' : 'bg-white text-dark']"
                                   :style="[serviceType == 'onsite' ? 'border: 2px solid transparent' : 'border: 2px solid #858585;']">
                              <span class="mr-1 d-none d-md-block"><i class='bx bx-walk'></i></span>
                              <span class="mb-0 font-weight-bold service-type">On site</span>
                            </label>
                          </div>
                        </div>
                        <!-- showing error -->
                        <div v-if="frontEndErrors.serviceType.length > 0" class="text-center pt-2">
                          <p class="text-danger mb-0 font-medium-1" v-for="(error, index) in frontEndErrors.serviceType"
                             :key="index">{{ error }}</p>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12 col-lg-4 mb-lg-0 mb-4" v-if="serviceType">
                      <div class="">
                        <div class="text-center mb-2">
                          <h4 class="mx-auto text-uppercase mb-0 pb-0">
                            <span class="text-uppercase text-bold-500">Requested Time Interval</span>
                          </h4>
                        </div>
                        <div class="row d-flex justify-content-center">
                          <div class="col-12 ">
                            <div class="form-group">
                              <label>Select Time Interval</label>
                              <VueMultiselect :allow-empty="false" v-model="selectedRequestedTimeInterval" class=""
                                              :options="timeIntervalOptions" :close-on-select="true"
                                              placeholder="Select time" label="name" track-by="value"
                                              :show-labels="false"/>
                            </div>
                          </div>
                        </div>
                        <!-- showing error -->
                        <div v-if="frontEndErrors.requestTimeIntervals.length > 0" class="text-center pt-2">
                          <p class="text-danger mb-0 font-medium-1"
                             v-for="(error, index) in frontEndErrors.requestTimeIntervals" :key="index">{{ error }}</p>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12 col-lg-4 mb-lg-0 mb-4"
                         v-if="serviceType && (selectedRequestedTimeInterval?.name)">
                      <div class="">
                        <div class="text-center mb-2">
                          <h4 class="mx-auto text-uppercase mb-0 pb-0">
                            <span class="text-uppercase text-bold-500">Date </span>
                          </h4>
                        </div>
                        <div class="row d-flex justify-content-center">
                          <div class="col-12">
                            <div class="form-group">
                              <label>Select Date</label>
                              <div class="d-flex align-items-center date-picker-wrapper">
                                <div class="w-100 ">
                                  <DatePicker v-model="selectedAppointmentDate" :update-on-input="true"
                                              :masks="{input: ['DD MMMM YYYY']}" :min-date='new Date()'
                                              :model-config="{ type: 'string', mask: 'YYYY-MM-DD'}">
                                    <template v-slot="{ inputValue ,togglePopover  }">
                                      <div class="d-flex align-items-center" @click="togglePopover()"
                                           style="cursor: pointer;">
                                        <i class='bx bx-calendar mr-1'></i>
                                        <input class="form-control" :value="inputValue" style="cursor: pointer;"/>
                                      </div>
                                    </template>
                                  </DatePicker>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- showing error -->
                        <div v-if="frontEndErrors.appointmentDate.length > 0" class="text-center pt-2">
                          <p class="text-danger mb-0 font-medium-1"
                             v-for="(error, index) in frontEndErrors.appointmentDate" :key="index">{{ error }}</p>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12 col-lg-4 mb-lg-0 mb-4"
                         v-if="serviceType && selectedAppointmentDate && (technicianAvailableTimeSlots.status === true)">
                      <div class="">
                        <div class="text-center mb-2">
                          <h4 class="mx-auto text-uppercase mb-0 pb-0">
                            <span class="text-uppercase text-bold-500">Time</span>
                          </h4>
                        </div>
                        <div class="row d-flex justify-content-center">
                          <div class="col-12 ">
                            <div class="form-group">
                              <label>Select Time</label>
                              <VueMultiselect :allow-empty="false" v-model="selectedAppointmentTime" class=""
                                              :options="postTechnicianAvailableTimeSlotsResponse.slots"
                                              :close-on-select="true" placeholder="Select time" label="name"
                                              track-by="value" :show-labels="false"/>
                            </div>
                          </div>
                        </div>
                        <!-- showing error -->
                        <div v-if="frontEndErrors.appointmentTime.length > 0" class="text-center pt-2">
                          <p class="text-danger mb-0 font-medium-1"
                             v-for="(error, index) in frontEndErrors.appointmentTime" :key="index">{{ error }}</p>
                        </div>
                      </div>
                    </div>

                  </div>

                  <div class="d-flex justify-content-between mt-5 pt-2 px-0 px-md-4">
                    <div class="">
                      <button @click="prev()" type="submit" class="btn border-primary text-primary font-weight-bold">
                        <span><i class='bx bx-chevron-left'></i></span> <span
                          class="d-none d-md-inline-block">Prev</span>
                      </button>
                    </div>
                    <div class="">
                      <button @click="serviceTypeStepNext()" type="submit"
                              class="btn border-primary text-primary font-weight-bold">
                        <span class="d-none d-md-inline-block">Next</span> <span><i
                          class='bx bx-chevron-right'></i></span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <!-- date selection -->
              <!-- <div class="card" v-if="step === 3">
                      <div class="card-body">
                          <div class="progress mb-3 mx-auto">
                              <div class="progress-bar progress-bar-striped active bg-primary" role="progressbar" style="width: 33.32%;" aria-valuenow="33.32" aria-valuemin="0" aria-valuemax="100"></div>
                          </div>
                          <div class="">
                              <div class="text-center mb-2">
                                  <h4 class="mx-auto text-uppercase mb-0 pb-0">
                                      <span class="text-uppercase text-bold-500">Date </span>
                                  </h4>
                              </div>
                              <div class="row d-flex justify-content-center">
                                  <div class="col-12 col-md-4">
                                      <div class="form-group">
                                          <label>Select Date</label>
                                          <div class="d-flex align-items-center date-picker-wrapper">
                                              <div class="w-100 ">
                                                  <DatePicker v-model="selectedAppointmentDate" :update-on-input="true" :masks="{input: ['DD MMMM YYYY']}" :min-date='new Date()' :model-config="{ type: 'string', mask: 'YYYY-MM-DD'}">
                                                      <template v-slot="{ inputValue ,togglePopover  }">
                                                          <div class="d-flex align-items-center" @click="togglePopover()" style="cursor: pointer;">
                                                              <i class='bx bx-calendar mr-1'></i>
                                                              <input class="form-control" :value="inputValue" style="cursor: pointer;" />
                                                          </div>
                                                      </template>
                                                  </DatePicker>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>

                              <div v-if="frontEndErrors.appointmentDate.length > 0" class="text-center pt-2">
                                  <p class="text-danger mb-0 font-medium-1" v-for="(error, index) in frontEndErrors.appointmentDate" :key="index">{{error}}</p>
                              </div>
                          </div>
                          <div class="d-flex justify-content-between mt-5 pt-2 px-0 px-md-4">
                              <div class="">
                                  <button @click="prev()" class="btn border-primary text-primary font-weight-bold">
                                      <span><i class='bx bx-chevron-left'></i></span> <span class="d-none d-md-inline-block">Prev</span>
                                  </button>
                              </div>
                              <div class="">
                                  <button @click="appointmentDateStepNext()" class="btn border-primary text-primary font-weight-bold">
                                      <span class="d-none d-md-inline-block">Next</span> <span><i class='bx bx-chevron-right'></i></span>
                                  </button>
                              </div>
                          </div>
                      </div>

                  </div> -->

              <div class="card" v-if="step === 3">
                <div class="card-body">
                  <div class="progress mb-3 mx-auto">
                    <div class="progress-bar progress-bar-striped active bg-primary" role="progressbar"
                         style="width: 37.5%;" aria-valuenow="37.5" aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                  <div class="">
                    <div class="text-center mb-2">
                      <h4 class="mx-auto text-uppercase mb-0 pb-0">
                        <span class="text-uppercase text-bold-500"> Technician</span>
                      </h4>
                    </div>
                    <div class="row d-flex justify-content-center">
                      <div class="col-12 col-md-4">
                        <div class="form-group">
                          <label>Select Technician</label>
                          <VueMultiselect :allow-empty="false" v-model="selectedTechnician" class=""
                                          :options="getAvailableTechniciansResponse.technicians" :close-on-select="true"
                                          placeholder="Select technician" label="name" track-by="id"
                                          :show-labels="false"/>
                        </div>
                      </div>
                    </div>
                    <!-- showing error -->
                    <div v-if="frontEndErrors.appointmentTechnician.length > 0" class="text-center pt-2">
                      <p class="text-danger mb-0 font-medium-1"
                         v-for="(error, index) in frontEndErrors.appointmentTechnician" :key="index">{{ error }}</p>
                    </div>
                  </div>
                  <div class="d-flex justify-content-between mt-5 pt-2 px-0 px-md-4">
                    <div class="">
                      <button @click="prev()" class="btn border-primary text-primary font-weight-bold">
                        <span><i class='bx bx-chevron-left'></i></span> <span
                          class="d-none d-md-inline-block">Prev</span>
                      </button>
                    </div>
                    <div class="">
                      <button @click="technicianStepNext()" class="btn border-primary text-primary font-weight-bold">
                        <span class="d-none d-md-inline-block">Next</span> <span><i
                          class='bx bx-chevron-right'></i></span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <!-- parking related information -->
              <div class="card" v-if="step === 4">
                <div class="card-body">
                  <div class="progress mb-3 mx-auto">
                    <div class="progress-bar progress-bar-striped active bg-primary" role="progressbar"
                         style="width: 50%;" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                  <div class="">
                    <div class="text-center mb-2">
                      <h4 class="mx-auto text-uppercase mb-0 pb-0">
                        <span class="text-uppercase text-bold-500">Parking Type</span>
                      </h4>
                    </div>
                    <div class="row d-flex justify-content-center">
                      <div class="col-12 col-md-4">
                        <div class="form-group">
                          <label>Select parking type</label>
                          <VueMultiselect :allow-empty="false" v-model="selectedParkingType" class=""
                                          :options="parkingTypes" :close-on-select="true"
                                          placeholder="Select parking type" label="name" track-by="value"
                                          :show-labels="false"/>
                        </div>
                      </div>
                    </div>
                    <!-- showing error -->
                    <div v-if="frontEndErrors.parkingType.length > 0" class="text-center pt-2">
                      <p class="text-danger mb-0 font-medium-1" v-for="(error, index) in frontEndErrors.parkingType"
                         :key="index">{{ error }}</p>
                    </div>
                  </div>
                  <div class="d-flex justify-content-between mt-5 pt-2 px-0 px-md-4">
                    <div class="">
                      <button @click="prev()" class="btn border-primary text-primary font-weight-bold">
                        <span><i class='bx bx-chevron-left'></i></span> <span
                          class="d-none d-md-inline-block">Prev</span>
                      </button>
                    </div>
                    <div class="">
                      <button @click="parkingTypeStepNext()" class="btn border-primary text-primary font-weight-bold">
                        <span class="d-none d-md-inline-block">Next</span> <span><i
                          class='bx bx-chevron-right'></i></span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <!-- time selection -->
              <!-- <div class="card" v-if="step === 5">
                      <div class="card-body">
                          <div class="progress mb-3 mx-auto">
                              <div class="progress-bar progress-bar-striped active bg-primary" role="progressbar" style="width: 50%;" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                          </div>
                          <div class="">
                              <div class="text-center mb-2">
                                  <h4 class="mx-auto text-uppercase mb-0 pb-0">
                                      <span class="text-uppercase text-bold-500">Time</span>
                                  </h4>
                              </div>
                              <div class="row d-flex justify-content-center">
                                  <div class="col-12 col-md-4">
                                      <div class="form-group">
                                          <label>Select Time</label>
                                          <VueMultiselect :allow-empty="false" v-model="selectedAppointmentTime" class="" :options="postTechnicianAvailableTimeSlotsResponse.slots" :close-on-select="true" placeholder="Select time" label="name" track-by="value" :show-labels="false" />
                                      </div>
                                  </div>
                              </div>

                              <div v-if="frontEndErrors.appointmentTime.length > 0" class="text-center pt-2">
                                  <p class="text-danger mb-0 font-medium-1" v-for="(error, index) in frontEndErrors.appointmentTime" :key="index">{{error}}</p>
                              </div>
                          </div>
                          <div class="d-flex justify-content-between mt-5 pt-2 px-0 px-md-4">
                              <div class="">
                                  <button @click="prev()" class="btn border-primary text-primary font-weight-bold">
                                      <span><i class='bx bx-chevron-left'></i></span> <span class="d-none d-md-inline-block">Prev</span>
                                  </button>
                              </div>
                              <div class="">
                                  <button @click="appointmentTimeStepNext()" class="btn border-primary text-primary font-weight-bold">
                                      <span class="d-none d-md-inline-block">Next</span> <span><i class='bx bx-chevron-right'></i></span>
                                  </button>
                              </div>
                          </div>
                      </div>

                  </div> -->

              <div class="card" v-if="step === 5">
                <div class="card-body">
                  <div class="progress mb-3 mx-auto">
                    <div class="progress-bar progress-bar-striped active bg-primary" role="progressbar"
                         style="width: 62.5%;" aria-valuenow="62.5" aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                  <div class="">
                    <div class="text-center mb-2">
                      <h4 class="mx-auto text-uppercase mb-0 pb-0">
                        <span class="text-uppercase text-bold-500">Service Platform</span>
                      </h4>
                    </div>
                    <div class="row d-flex justify-content-center">
                      <div class="col-12 col-md-4">
                        <div class="form-group">
                          <label>Select Platform</label>
                          <VueMultiselect :allow-empty="false" v-model="selectedServicePlatform" class=""
                                          :options="servicePlatforms" :close-on-select="true"
                                          placeholder="Select service platform type" label="name" track-by="value"
                                          :show-labels="false"/>
                        </div>
                      </div>
                    </div>
                    <!-- showing error -->
                    <div v-if="frontEndErrors.servicePlatform.length > 0" class="text-center pt-2">
                      <p class="text-danger mb-0 font-medium-1" v-for="(error, index) in frontEndErrors.servicePlatform"
                         :key="index">{{ error }}</p>
                    </div>
                  </div>
                  <div class="d-flex justify-content-between mt-5 pt-2 px-0 px-md-4">
                    <div class="">
                      <button @click="prev()" class="btn border-primary text-primary font-weight-bold">
                        <span><i class='bx bx-chevron-left'></i></span> <span
                          class="d-none d-md-inline-block">Prev</span>
                      </button>
                    </div>
                    <div class="">
                      <button @click="servicePlatformStepNext()"
                              class="btn border-primary text-primary font-weight-bold">
                        <span class="d-none d-md-inline-block">Next</span> <span><i
                          class='bx bx-chevron-right'></i></span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card" v-if="step === 6">
                <div class="card-body">
                  <div class="progress mb-3 mx-auto">
                    <div class="progress-bar progress-bar-striped active bg-primary" role="progressbar"
                         style="width: 75%;" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                  <div class="">
                    <div class="text-center mb-2">
                      <h4 class="mx-auto text-uppercase mb-0 pb-0">
                        <span class="text-uppercase text-bold-500">Service</span>
                      </h4>
                    </div>
                    <div class="row d-flex justify-content-center">
                      <div class="col-12 col-md-4">
                        <div class="form-group">
                          <label>Select Service</label>
                          <VueMultiselect :allow-empty="false" v-model="selectedService" class="" :options="services"
                                          :close-on-select="true" placeholder="Select service" label="name"
                                          track-by="id" :show-labels="false"/>
                        </div>
                      </div>
                    </div>
                    <!-- showing error -->
                    <div v-if="frontEndErrors.service.length > 0" class="text-center pt-2">
                      <p class="text-danger mb-0 font-medium-1" v-for="(error, index) in frontEndErrors.service"
                         :key="index">{{ error }}</p>
                    </div>
                  </div>
                  <div class="d-flex justify-content-between mt-5 pt-2 px-0 px-md-4">
                    <div class="">
                      <button @click="prev()" class="btn border-primary text-primary font-weight-bold">
                        <span><i class='bx bx-chevron-left'></i></span> <span
                          class="d-none d-md-inline-block">Prev</span>
                      </button>
                    </div>
                    <div class="">
                      <button @click="serviceStepNext()" class="btn border-primary text-primary font-weight-bold">
                        <span class="d-none d-md-inline-block">Next</span> <span><i
                          class='bx bx-chevron-right'></i></span>
                      </button>
                    </div>
                  </div>
                </div>

              </div>
              <div class="card" v-if="step === 7">
                <div class="card-body">
                  <div class="progress mb-3 mx-auto">
                    <div class="progress-bar progress-bar-striped active bg-primary" role="progressbar"
                         style="width: 87.5%;" aria-valuenow="87.5" aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                  <div class="">
                    <div class="text-center mb-2">
                      <h4 class="mx-auto text-uppercase mb-0 pb-0">
                        <span class="text-uppercase text-bold-500">Appointment Note</span>
                      </h4>
                    </div>
                    <div class="row d-flex justify-content-center">
                      <div class="col-12 col-md-5">
                        <div class="form-group">
                          <label>Describe Problem</label>
                          <textarea v-model="describeProblem" class="form-control" name="" id="" rows="10"></textarea>
                        </div>
                      </div>
                    </div>
                    <!-- showing error -->
                    <div v-if="frontEndErrors.describeProblem.length > 0" class="text-center pt-2">
                      <p class="text-danger mb-0 font-medium-1" v-for="(error, index) in frontEndErrors.describeProblem"
                         :key="index">{{ error }}</p>
                    </div>
                  </div>
                  <div class="d-flex justify-content-between mt-5 pt-2 px-0 px-md-4">
                    <div class="">
                      <button @click="prev()" class="btn border-primary text-primary font-weight-bold">
                        <span><i class='bx bx-chevron-left'></i></span> <span
                          class="d-none d-md-inline-block">Prev</span>
                      </button>
                    </div>
                    <div class="">
                      <button @click="describeProblemStepNext()"
                              class="btn border-primary text-primary font-weight-bold">
                        <span class="d-none d-md-inline-block">Next</span> <span><i
                          class='bx bx-chevron-right'></i></span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="" v-if="step === 8">
                <div class="">
                  <div class="progress mb-3 mx-auto">
                    <div class="progress-bar progress-bar-striped active bg-primary" role="progressbar"
                         style="width: 100%;" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                  </div>

                  <div class="row">
                    <div class="col-lg-4 mt-1 mt-lg-0">
                      <div class="card h-100 ">
                        <div class="card-body">

                          <div class="">
                            <h6 class="text-bold-500 text-uppercase"> Customer Information <span @click="step=1"
                                                                                                 title="Update customer information"
                                                                                                 class="single-edit-btn text-primary cursor-pointer"><i
                                class='bx bx-edit-alt' :style="{fontSize:'16px !important'}"></i></span></h6>
                          </div>

                          <div class="">
                            <div class="form-group" v-if="user.firstName">

                              <span class="text-bold-500">Customer Name : </span>
                              <span class="">{{ `${user.firstName} ${user.lastName}` }}</span>

                            </div>
                            <div class="form-group" v-if="user.email">
                              <div class="">
                                <span class="text-bold-500">Customer Email : </span>
                                <span class="">{{ user.email }}</span>
                              </div>
                            </div>
                            <div class="form-group" v-if="user.phoneNumber">
                              <div class="">
                                <span class="text-bold-500">Customer Phone : </span>
                                <span class="">{{ user.phoneNumber }}</span>
                              </div>
                            </div>
                            <!-- <div class="form-group" v-if="existingCustomerBusiness.businessId">

                                    <span class="text-bold-500">Customer Business Name : </span>
                                    <span class="">{{existingCustomerBusiness.businessName}}</span>
                                </div> -->
                            <div class="form-group">
                              <div class="">
                                <span class="text-bold-500">Billing Address : </span>
                                <span class="">{{ userFullAddress }}</span>
                              </div>
                            </div>
                            <div class="form-group">
                              <div class="">
                                <span class="text-bold-500">Service Address : </span>
                                <span class="">{{ serviceFullAddress }}</span>
                              </div>
                            </div>
                          </div>
                          <hr>
                          <div class="">
                            <h6 class="text-bold-500 text-uppercase"> Appointment Information</h6>
                          </div>
                          <div class="form-group">
                            <div class="">
                              <span class="text-bold-500"> Service preference : </span>
                              <span class="text-capitalize">{{ serviceType }}</span> <span @click="step=1"
                                                                                           title="Update service preference"
                                                                                           class="single-edit-btn text-primary cursor-pointer"><i
                                class='bx bx-edit-alt' :style="{fontSize:'15px !important'}"></i></span>
                            </div>
                          </div>

                          <!-- <div class="form-group">
                                  <div class="">
                                      <span class="text-bold-500"> Appointment Type : </span>
                                      <span class="text-capitalize">{{ appointmentType  }}</span> <span @click="step=2" title="Update appointment type" class="single-edit-btn text-primary cursor-pointer"><i class='bx bx-edit-alt' :style="{fontSize:'15px !important'}"></i></span>
                                  </div>
                              </div> -->

                          <div class="form-group">
                            <div class="">
                              <span class="text-bold-500"> Appointment Date : </span>
                              <span class="text-capitalize">{{ selectedAppointmentDate }}</span> <span @click="step=2"
                                                                                                       title="Update appointment date"
                                                                                                       class="single-edit-btn text-primary cursor-pointer"><i
                                class='bx bx-edit-alt' :style="{fontSize:'15px !important'}"></i></span>
                            </div>
                          </div>

                          <div class="form-group">
                            <div class="">
                              <span class="text-bold-500"> Appointment Time : </span>
                              <span class="text-capitalize">{{ selectedAppointmentTime.name }}</span> <span
                                @click="step=2" title="Update appointment time"
                                class="single-edit-btn text-primary cursor-pointer"><i class='bx bx-edit-alt'
                                                                                       :style="{fontSize:'15px !important'}"></i></span>
                            </div>
                          </div>

                          <div class="form-group">
                            <div class="">
                              <span class="text-bold-500"> Technician Name : </span>
                              <span class="text-capitalize">{{ selectedTechnician.name }}</span> <span @click="step=4"
                                                                                                       title="Update technician name"
                                                                                                       class="single-edit-btn text-primary cursor-pointer"><i
                                class='bx bx-edit-alt' :style="{fontSize:'15px !important'}"></i></span>
                            </div>
                          </div>

                          <div class="form-group">
                            <div class="">
                              <span class="text-bold-500"> Service Platform : </span>
                              <span class="text-capitalize">{{ selectedServicePlatform.name }}</span> <span
                                @click="step=5" title="Update service platform"
                                class="single-edit-btn text-primary cursor-pointer"><i class='bx bx-edit-alt'
                                                                                       :style="{fontSize:'15px !important'}"></i></span>
                            </div>
                          </div>

                          <div class="form-group">
                            <div class="">
                              <span class="text-bold-500"> Service Name : </span>
                              <span class="text-capitalize">{{ selectedService.name }}</span> <span @click="step=6"
                                                                                                    title="Update service name"
                                                                                                    class="single-edit-btn text-primary cursor-pointer"><i
                                class='bx bx-edit-alt' :style="{fontSize:'15px !important'}"></i></span>
                            </div>
                          </div>

                          <div class="form-group">
                            <div class="">
                              <span class="text-bold-500"> Problem Note : </span>
                              <span class="text-capitalize">{{ describeProblem }}</span> <span @click="step=7"
                                                                                               title="Update problem note"
                                                                                               class="single-edit-btn text-primary cursor-pointer"><i
                                class='bx bx-edit-alt' :style="{fontSize:'15px !important'}"></i></span>
                            </div>
                          </div>

                        </div>

                      </div>

                    </div>
                    <div class="col-lg-4 mt-1 mt-lg-0">
                      <div class="card h-100">
                        <div class="card-body">
                          <p class="text-bold-500">DISCOUNT & OTHERS</p>
                          <div class="">

                            <div class="">
                              <div class="">
                                <div class="custom-control custom-checkbox d-flex align-items-baseline">
                                  <input class="custom-control-input" type="checkbox" id="applyCoupon"
                                         v-model="applyCoupon">
                                  <label class="custom-control-label" for="applyCoupon">Apply Coupon </label><small
                                    data-toggle="modal" data-target="#AvailableCouponsModal" class="text-primary"
                                    v-if="coupons.length > 0">(Click to see all available coupon)</small>
                                </div>
                              </div>
                              <div v-if="applyCoupon">
                                <div class="row pt-1 pb-0">
                                  <div class="col-9 align-self-center"><input placeholder="Enter a Coupon" type="text"
                                                                              v-model="couponCode"
                                                                              class="w-100 appointment-input-field">
                                  </div>
                                  <div class="col-3 align-self-center pl-0 d-flex align-items-center">
                                    <button class="btn btn-sm"
                                            :class="[preAppointmentResponse.coupon_discount.validation_status === true ? 'btn-danger' : 'btn-primary']"
                                            @click="applyCouponHandler">
                                      {{
                                        preAppointmentResponse.coupon_discount.validation_status === true ? "Remove" : "Apply"
                                      }}
                                    </button>
                                  </div>
                                </div>
                                <div class="pb-1"
                                     v-if="preAppointmentResponse.coupon_discount.validation_status === false">
                                  <p class="text-danger">{{ preAppointmentResponse.coupon_discount.message }}</p>
                                </div>
                                <div class="pb-1"
                                     v-if="preAppointmentResponse.coupon_discount.validation_status === true">
                                  <p class="text-success">{{ preAppointmentResponse.coupon_discount.message }}</p>
                                </div>

                              </div>
                            </div>

                            <div class="">

                              <div v-if="preAppointmentResponse.remaining_credited_payment > 0"
                                   class="custom-control custom-checkbox d-flex align-items-center">
                                <input class="custom-control-input" type="checkbox" id="applyCredit"
                                       v-model="applyCredit">
                                <label class="custom-control-label" for="applyCredit">Apply Credit (<span
                                    class="display-inline-block text-danger">{{
                                    `Remaining credit: ${parseFloat(preAppointmentResponse.remaining_credited_payment / 100).toFixed(2)}`
                                  }}</span>)</label>
                              </div>

                              <div v-if="applyCredit">
                                <div class="row pt-1 pb-0">
                                  <div class="col-9 align-self-center">
                                    <!--<span class="text-success font-weight-light">{{`Remaining credit: ${preAppointmentResponse.remaining_credited_payment}`}}</span>-->
                                    <input placeholder="Enter credit amount" v-model="creditCode" type="text"
                                           class="w-100 appointment-input-field">
                                  </div>
                                  <div class="col-3 align-self-center pl-0  align-items-center">
                                    <!-- <div>
                                            <span for="" style="visibility: hidden;">dummy text</span>
                                        </div> -->
                                    <button class="btn btn-primary btn-sm" @click="applyCreditHandler">Apply</button>
                                  </div>
                                </div>
                                <div
                                    v-if="preAppointmentResponse.credited_payment_discount.validation_status === false">
                                  <p class="text-danger">
                                    {{ preAppointmentResponse.credited_payment_discount.message }}</p>
                                </div>
                                <div v-if="preAppointmentResponse.credited_payment_discount.validation_status === true">
                                  <p class="text-success">
                                    {{ preAppointmentResponse.credited_payment_discount.message }}</p>
                                </div>
                              </div>
                            </div>

                            <div class="row">
                              <div class="col-12">
                                <p class="pt-2 text-bold-500">Discount</p>
                              </div>
                              <div class="col-12 ">
                                <!-- any -->
                                <div class="custom-control-inline custom-control custom-radio">
                                  <input type="radio" class="custom-control-input" id="anyDiscounts" v-model="discount"
                                         name="discount_type" value="any">
                                  <label class="custom-control-label font-weight-bold" for="anyDiscounts">Any</label>
                                </div>
                                <!-- five percent -->
                                <div class="custom-control-inline custom-control custom-radio">
                                  <input @change="applyDiscountHandler" type="radio" class="custom-control-input"
                                         id="fivePerDiscount" v-model="discount" name="discount_type" value="5">
                                  <label class="custom-control-label font-weight-bold" for="fivePerDiscount">5%
                                    discount</label>
                                </div>
                                <!-- ten percent -->
                                <div class="custom-control-inline custom-control custom-radio">
                                  <input @change="applyDiscountHandler" type="radio" class="custom-control-input"
                                         id="tenPerDiscount" v-model="discount" name="discount_type" value="10">
                                  <label class="custom-control-label font-weight-bold" for="tenPerDiscount">10%
                                    discount</label>
                                </div>
                                <!-- twenty percent -->
                                <div class="custom-control-inline custom-control custom-radio">
                                  <input @change="applyDiscountHandler" type="radio" class="custom-control-input"
                                         id="twentyPerDiscount" v-model="discount" name="discount_type" value="20">
                                  <label class="custom-control-label font-weight-bold" for="twentyPerDiscount">20%
                                    discount</label>
                                </div>
                                <div class="row py-1" v-if="discount === 'any'">
                                  <div class="col-9 align-self-center"><input placeholder="Enter exact ammount"
                                                                              v-model="discountAmount" type="text"
                                                                              class="w-100 appointment-input-field">
                                  </div>
                                  <div class="col-3 align-self-center pl-0 d-flex align-items-center">
                                    <button class="btn btn-primary btn-sm" @click="applyDiscountHandler">Apply</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 mt-1 mt-lg-0">
                      <div class="card h-100">
                        <div class="card-body d-flex flex-column justify-content-between">
                          <div>
                            <p class="text-bold-500">PAYMENT DETAILS</p>
                            <div class="row">
                              <div class="col-7 text-left"><span class="text-bold-400">Service:</span></div>
                              <div class="col-5 text-right"><span class="">${{
                                  parseFloat(preAppointmentResponse.service_price / 100).toFixed(2)
                                }}</span></div>
                            </div>

                            <div class="row">
                              <div class="col-7 text-left"><span class="text-bold-400">Surcharge <small
                                  class="text-primary cursor-pointer" data-toggle="modal"
                                  data-target="#SurChargeDetailsModal">(view details)</small> :</span></div>
                              <div class="col-5 text-right"><span class="">${{
                                  parseFloat(preAppointmentResponse.total_surcharge_amount / 100).toFixed(2)
                                }} </span></div>
                            </div>
                            <hr>
                            <div class="row">
                              <div class="col-7 text-left"><span class="text-bold-400">Subtotal:</span></div>
                              <div class="col-5 text-right"><span class=""> ${{
                                  parseFloat(preAppointmentResponse.subtotal_amount / 100).toFixed(2)
                                }} </span></div>
                            </div>
                            <div class="row">
                              <div class="col-7 text-left"><span class="text-bold-400">Discount <small
                                  v-if="(preAppointmentResponse.total_discount_amount / 100).toFixed(2) > 0 "
                                  class="text-primary cursor-pointer" data-toggle="modal"
                                  data-target="#DiscountDetailsModal">(view details)</small> :</span></div>
                              <div class="col-5 text-right"><span class=""> ${{
                                  parseFloat(preAppointmentResponse.total_discount_amount / 100).toFixed(2)
                                }} </span></div>
                            </div>
                            <div class="row" v-if="preAppointmentResponse.gst_charge.applied_status">
                              <div class="col-7 text-left"><span class="text-bold-400">GST:</span></div>
                              <div class="col-5 text-right"><span class=""> ${{
                                  parseFloat(preAppointmentResponse.gst_charge.amount / 100).toFixed(2)
                                }} </span></div>
                            </div>
                            <hr>
                            <div class="row" v-if="preAppointmentResponse.gst_charge.applied_status">
                              <div class="col-7 text-left"><span class="text-bold-600">Grand Total:</span></div>
                              <div class="col-5 text-right text-bold-600"><span
                                  class=""> ${{
                                  parseFloat(preAppointmentResponse.grand_total / 100).toFixed(2)
                                }} </span>
                              </div>
                            </div>
                            <div v-if="paymentType === 'cardPayment'" class="row py-1">
                              <div class="col-12">
                                <div class="border p-1">
                                  <h6 class="text-danger">Card Surcharge details : </h6>
                                  <div v-if="settingPaymentCardSurcharge.value.status">
                                    <div v-for="surCharge in settingPaymentCardSurcharge.value.details" :key="surCharge"
                                         class="row">
                                      <div class="col-8 text-left">
                                        <p class="text-bold-400 pb-0 mb-0">{{ surCharge.name.replaceAll('_', ' ') }}:
                                          ${{
                                            parseFloat(parseInt((parseFloat(preAppointmentResponse?.grand_total) * parseFloat(surCharge.rate)) / 100) / 100).toFixed(2)
                                          }}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-12">
                                <div class="form-group">
                                  <div class="controls">
                                    <label>Add Payment</label>
                                    <div style="margin-top: .5rem">
                                      <div
                                          class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center">
                                        <div>
                                          <input v-model="withPayment" type="checkbox" class="custom-control-input"
                                                 id="withPaymentOrWithoutPayment">
                                          <label class="custom-control-label mr-1"
                                                 for="withPaymentOrWithoutPayment"></label>
                                        </div>
                                        <span class="font-medium-1">{{ withPayment ? "Yes" : "No" }}</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div v-if="withPayment">
                              <h5 class="mt-1 text-bold-500">Payment Method</h5>
                              <div v-if="canAny(['card-payment-create','appointment-create'])"
                                   class="custom-control custom-radio d-flex align-items-center"
                                   :style="{marginBottom:'3px'}">
                                <input type="radio" class="custom-control-input" v-model="paymentType"
                                       name="customRadio" value="cardPayment" id="CardPayment">
                                <label class="custom-control-label text-bold-400" for="CardPayment">Card Payment <small
                                    class="text-primary font-weight-bold cursor-pointer"
                                    v-if="settingPaymentCardSurcharge.length>0" data-toggle="modal"
                                    data-target="#CardSurchargeDetailsModal">(surcharge details)</small></label>
                              </div>

                              <div v-if="paymentType === 'cardPayment'" class="row mt-1">
                                <div class="col-12 ">
                                  <div class="form-group">
                                    <label for="first-name-icon">Cardholder name</label>
                                    <div class="position-relative has-icon-left">
                                      <input type="text" v-model="cardName" class="form-control" name="fname-icon"
                                             placeholder="Enter cardholder name">
                                      <div class="form-control-position">
                                        <i class='bx bx-user'></i>
                                      </div>
                                    </div>
                                    <!-- backend error msg -->
                                    <div
                                        v-if="cardTokenBackendErrors.card_name !== undefined && cardTokenBackendErrors.card_name.length > 0 "
                                        class="text-danger">
                                      <p class="mb-0" v-for="(error,index) in cardTokenBackendErrors.card_name"
                                         :key="index"> {{ error }}</p>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-12 ">
                                  <div class="form-group">
                                    <label for="email-id-icon">Card number</label>
                                    <div class="position-relative has-icon-left">
                                      <input type="text" v-model="cardNumber" class="form-control" name="email-id-icon"
                                             placeholder="Enter card number">
                                      <div class="form-control-position">
                                        <i class='bx bx-credit-card-alt'></i>
                                      </div>
                                    </div>
                                    <!-- backend error msg -->
                                    <div
                                        v-if="cardTokenBackendErrors.card_number !== undefined && cardTokenBackendErrors.card_number.length > 0 "
                                        class="text-danger">
                                      <p class="mb-0" v-for="(error,index) in cardTokenBackendErrors.card_number"
                                         :key="index"> {{ error }}</p>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-12 col-md-6">
                                  <div class="form-group">
                                    <label for="contact-info-icon">Expiry Date</label>
                                    <div class="position-relative has-icon-left">
                                      <!-- <input type="text" v-model="cardExpiryDate" id="expiryDateInput" class="form-control" name="contact-icon" placeholder="MM/YY" maxlength="5" > -->
                                      <input type="text" v-model="cardExpiryDate" id="expiryDateInput"
                                             class="form-control" name="contact-icon" placeholder="MM/YY" maxlength="5"
                                             :keyup="this.cardExpiryDate=this.cardExpiryDate.replace(/^(\d\d)(\d)$/g,'$1/$2').replace(/^(\d\d\/\d\d)(\d+)$/g,'$1/$2')">
                                      <div class="form-control-position">
                                        <i class='bx bx-calendar-check'></i>
                                      </div>
                                    </div>
                                    <!-- backend error msg -->
                                    <div
                                        v-if="cardTokenBackendErrors.card_expire_month !== undefined && cardTokenBackendErrors.card_expire_month.length > 0 "
                                        class="text-danger">
                                      <p class="mb-0" v-for="(error,index) in cardTokenBackendErrors.card_expire_month"
                                         :key="index"> {{ error }}</p>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-12 col-md-6">
                                  <div class="form-group">
                                    <div class="d-flex align-items-center">
                                      <label for="password-icon">CVV &nbsp;</label>
                                      <span style="cursor:pointer" class="appointment-info-icon"><i
                                          class='bx bx-info-circle bx-xs'></i></span>
                                      <small class="max-value"> &nbsp;max 4 digit</small>
                                    </div>
                                    <div class="position-relative has-icon-left">
                                      <input ref="cvvInput" type="text" v-model="cardCvc" class="form-control"
                                             name="contact-icon" placeholder="Enter CVV">
                                      <div class="form-control-position">
                                        <i class='bx bx-lock-alt'></i>
                                      </div>
                                    </div>
                                    <!-- backend error msg -->
                                    <div
                                        v-if="cardTokenBackendErrors.card_cvc !== undefined && cardTokenBackendErrors.card_cvc.length > 0 "
                                        class="text-danger">
                                      <p class="mb-0" v-for="(error,index) in cardTokenBackendErrors.card_cvc"
                                         :key="index"> {{ error }}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="d-flex">
                                <div v-if="can('cash-payment-create')"
                                     class="custom-control custom-radio d-flex align-items-center">
                                  <input v-model="paymentType" value="cashPayment" type="radio"
                                         class="custom-control-input" name="customRadio" id="cashPayment">
                                  <label class="custom-control-label text-bold-400 " for="cashPayment">Cash
                                    payment</label>

                                </div>
                                <div v-if="paymentType === 'cashPayment'"
                                     class="custom-control custom-checkbox d-flex align-items-baseline ml-1">
                                  <input class="custom-control-input" style="cursor: pointer;" type="checkbox"
                                         id="cashReceived" v-model="isCashReceived">
                                  <label class="custom-control-label text-warning" style="cursor: pointer;"
                                         for="cashReceived">Cash Received </label>
                                </div>
                              </div>

                              <div v-if="can('eft-payment-create')"
                                   class="custom-control custom-radio d-flex align-items-center">
                                <input v-model="paymentType" value="eftPayment" type="radio"
                                       class="custom-control-input" name="customRadio" id="eftPayment">
                                <label class="custom-control-label text-bold-400" for="eftPayment">EFT payment</label>
                              </div>
                              <div v-if="paymentType === 'eftPayment'">
                                <div class="row py-1">
                                  <div class="col-12 col-md-12  align-self-center"><input placeholder="Enter reference"
                                                                                          v-model="eftReference"
                                                                                          type="text"
                                                                                          class="w-100 appointment-input-field">
                                  </div>
                                </div>
                                <!-- showing error -->
                                <div v-if="frontEndErrors.eftReference.length > 0" class="text-left">
                                  <p class="text-danger mb-0 font-medium-1"
                                     v-for="(error, index) in frontEndErrors.eftReference" :key="index">{{ error }}</p>
                                </div>
                              </div>
                            </div>
                            <!-- showing error -->
                            <div v-if="frontEndErrors.paymentTypeSelection.length > 0" class="text-left pt-2">
                              <p class="text-danger mb-0 font-medium-1"
                                 v-for="(error, index) in frontEndErrors.paymentTypeSelection" :key="index">
                                {{ error }}</p>
                            </div>

                            <hr>
                            <div class="">
                              <p class="text-bold-500">Notify Email</p>
                              <div class="">
                                <div class="">
                                  <div class="">
                                    <div class="custom-control custom-checkbox d-flex align-items-baseline">
                                      <input class="custom-control-input" style="cursor: pointer;" type="checkbox"
                                             id="notifyCustomer" v-model="notifyToCustomer">
                                      <label class="custom-control-label" style="cursor: pointer;" for="notifyCustomer">Notify
                                        customer </label>
                                    </div>
                                  </div>

                                </div>
                              </div>

                            </div>
                          </div>

                          <div class="py-2">
                            <button @click="createAppointmentHandler" class="btn btn-primary btn-block text-uppercase">
                              Create Appointment
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>

                <div class="d-flex justify-content-between mt-5 pt-2 px-0 px-md-4">
                  <div class="">
                    <button @click="prev()" class="btn border-primary text-primary font-weight-bold">
                      <span><i class='bx bx-chevron-left'></i></span> <span class="d-none d-md-inline-block">Prev</span>
                    </button>
                  </div>
                  <!-- <div class="">
                          <button @click="appointmentDetailsStepNext()" class="btn border-primary text-primary font-weight-bold">
                              <span class="d-none d-md-inline-block">Next</span> <span><i class='bx bx-chevron-right'></i></span>
                          </button>
                      </div> -->
                </div>
              </div>
            </div>
          </div>
          <div class="" data-toggle="modal" data-target="#appointmentCreateSuccessModal">
          </div>
          <div class="" data-toggle="modal" data-target="#PostOnSiteAddressDistanceResponseModal">
          </div>
          <div class="" data-toggle="modal" data-target="#postTechnicianAvailableTimeSlotsResponseModal">
          </div>
          <div class="" data-toggle="modal" data-target="#AvailableCouponsModal">
          </div>
          <div class="" data-toggle="modal" data-target="#GetAvailableTechniciansResponse"></div>
          <div class="" data-toggle="modal" data-target="#AvailableCouponsModal"></div>

          <!-- appointment create success message show modal  -->
          <div class="modal fade " id="appointmentCreateSuccessModal" tabindex="-1" role="dialog"
               aria-labelledby="appointmentCreateSuccessModal" data-keyboard="false" data-backdrop="static">
            <div class="modal-dialog modal-dialog-scrollable modal-md modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-header bg-transparent border-0">
                  <h1></h1>
                  <button type="button" @click="closeAppointmentSuccessModal" class="close text-primary">
                    <i class="bx bx-x"></i>
                  </button>
                </div>
                <div class="modal-body text-center">
                  <div class="appointmentSuccessfulWrapper">
                    <img src="http://goactionstations.co.uk/wp-content/uploads/2017/03/Green-Round-Tick.png" alt="">
                    <h4 class="" style="color: #5C5C5C">Appointment created successfully !</h4>
                    <h5 class="appointmentId">Reference No: <span
                        class="text-primary">{{ appointment.reference }}</span></h5>
                  </div>
                </div>
                <div class="modal-footer border-0 py-1">
                  <button type="button" class="btn btn-primary" @click="closeAppointmentSuccessModal">
                    <i class="bx bx-x d-block d-sm-none"></i>
                    <span class="d-none d-sm-block">Close</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <PostOnSiteAddressDistanceResponseModal
          :postOnSiteAddressDistanceResponse="postOnSiteAddressDistanceResponse.message"/>
      <PostTechnicianAvailableTimeSlotsResponseModal
          :postTechnicianAvailableTimeSlotsResponse="postTechnicianAvailableTimeSlotsResponse.message"/>
      <!-- <PresStoreAppointmentErrorsResponseModal :preAppointmentResponseErrors="preAppointmentResponseErrors" /> -->
      <GetAvailableTechniciansResponse :getAvailableTechniciansResponse="getAvailableTechniciansResponse.message"/>
      <SurChargeDetailsModal :preAppointmentResponse="{...preAppointmentResponse}"/>
      <DiscountDetailsModal :preAppointmentResponse="{...preAppointmentResponse}"/>
      <CardSurchargeDetailsModal :settingPaymentCardSurcharge="settingPaymentCardSurcharge"/>
      <AddressUpdateModal modal-name="addressUpdateModal" address-update-for-model-name="customer"
                          :model-id="customer?.id ?? ''" :user-id="user.id" :selected-address-data="selectedAddressData"
                          :business-status="appointmentType === 'business'"
                          @updateModalAddressData="updateModalAddressData"/>
      <AvailableCouponsModal modal-name="AvailableCouponsModal" @emitApplyCoupon="applyCouponEmitHandler"/>

      <UserAddressCreateOnlyModal :model-primary-address-id="address.id" :user-id="user.id"
                                  :business-status="appointmentType==='business'" modal-name="userAddressCreateModal"
                                  @emitUserAddress="updateServiceAddressData"/>

    </template>
  </AppLayout>
</template>

<script>
import AppLayout from '@/layouts/backEnd/AppLayout.vue';
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
import {DatePicker} from 'v-calendar';

import {mapActions, mapGetters} from "vuex"

import PostOnSiteAddressDistanceResponseModal
  from '@/views/backEnd/appointments/appointmentCreate/includes/PostOnSiteAddressDistanceResponseModal.vue';
import PostTechnicianAvailableTimeSlotsResponseModal
  from '@/views/backEnd/appointments/appointmentCreate/includes/PostTechnicianAvailableTimeSlotsResponseModal.vue';
import SurChargeDetailsModal from '@/views/backEnd/appointments/appointmentCreate/includes/SurChargeDetailsModal.vue';
import DiscountDetailsModal from '@/views/backEnd/appointments/appointmentCreate/includes/DiscountDetailsModal.vue';
import GetAvailableTechniciansResponse
  from '@/views/backEnd/appointments/appointmentCreate/includes/GetAvailableTechniciansResponse.vue';
import CardSurchargeDetailsModal
  from "@/views/backEnd/appointments/appointmentCreate/includes/CardSurchargeDetailsModal";
import AddressUpdateModal from "@/components/backEnd/modal/AddressUpdateModal";
import UserAddressCreateOnlyModal from "@/components/backEnd/modal/UserAddressCreateOnlyModal";
import AvailableCouponsModal from "@/views/backEnd/appointments/appointmentCreate/includes/AvailableCouponsModal";
import Authorization from "@/components/backEnd/mixins/Authorization";
import AddressMixin from "@/components/backEnd/mixins/AddressMixin";

export default {
  components: {
    AvailableCouponsModal,
    CardSurchargeDetailsModal,

    AppLayout,
    DatePicker,

    PostOnSiteAddressDistanceResponseModal,
    PostTechnicianAvailableTimeSlotsResponseModal,
    GetAvailableTechniciansResponse,
    SurChargeDetailsModal,
    DiscountDetailsModal,
    AddressUpdateModal,
    UserAddressCreateOnlyModal
    // PresStoreAppointmentErrorsResponseModal
  },
  name: "CreateAppointments",
  mixins: [ShowToastMessage, Loader, Authorization, AddressMixin],
  props: ['selectedServiceType', 'customerId'],
  data() {
    return {
      step: 1,
      selectedRequestedTimeInterval: {
        name: '1 Hour 00 min',
        value: "01:00"
      },

      serviceType: '',
      appointmentType: '',
      customerType: '',
      selectedSearchedCustomer: '',
      frontEndErrors: {
        requestTimeIntervals: [],
        serviceType: [],
        customerType: [],
        parkingType: [],
        appointmentDate: [],
        appointmentTime: [],
        service: [],
        servicePlatform: [],
        describeProblem: [],
        appointmentTechnician: [],
        customerSearch: [],
        paymentTypeSelection: [],
        eftReference: []

      },

      customersSearchKey: '',

      getCustomersListParams: {
        where_has_user_search_query: '',
        with_relation: ['user'],
        order_by_id: 'DESC',

      },
      getServiceParams: {

        status: 1,

      },
      getCustomerParams: {
        with_relation: ['user', 'address', 'address.business'],
      },
      getSettingsParams: {
        type: ['default', 'appointment', 'payment'],
        key: ['default_state', 'default_parking_type', 'appointment_service_platform', 'payment_card_surcharge'],
      },
      searchCustomersDebounce: null,

      user: {
        id: '',
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: '',
        status: ''
      },

      // this address is for billing address
      address: {
        id: '',
        subpremise: '',
        street: '',
        suburb: '',
        state: '',
        postCode: '',
        country: 'Australia',
        business: {
          'id': '',
          name: '',
        },
      },

      // this is for service address
      serviceAddress: {
        id: '',
        subpremise: '',
        street: '',
        suburb: '',
        state: '',
        postCode: '',
        country: 'Australia',
        business: {
          'id': '',
          name: '',
        },
      },
      selectedParkingType: '',
      selectedAppointmentDate: '',
      selectedAppointmentTime: '',
      selectedTechnician: '',
      selectedService: '',
      selectedServicePlatform: '',
      describeProblem: '',
      applyCoupon: false,
      couponCode: '',
      creditCode: '',
      applyCredit: false,
      discount: '',
      discountAmount: '',
      paymentType: '',
      postOnSiteAddressDistanceResponse: {
        status: '',
        message: ''
      },
      postTechnicianAvailableTimeSlotsResponse: {
        status: '',
        message: '',
        slots: []
      },
      getAvailableTechniciansResponse: {
        status: '',
        message: '',
        technicians: []
      },

      preAppointmentResponseErrors: [],

      preAppointmentResponse: {
        message: '',
        status: '',

        service_unit_price: '', // appointment create
        service_price: '', // charges
        service_quantity: '', //  appoinment create

        remaining_credited_payment: '',
        same_day_surcharge: {
          applied_status: '',
          amount: ''
        },
        timebase_surcharge: {
          applied_status: '',
          amount: '',
        },
        weekend_surcharge: {
          applied_status: '',
          amount: ''
        },
        distance_surcharge: {
          applied_status: '',
          message: '',
          distance_value: '',
          distance_value_in_km: '',
          amount: '',
        },
        holiday_surcharge: {
          applied_status: '',
          amount: '',
        },
        fuel_levy: {
          applied_status: '',
          amount: '',
        },
        city_area_surcharge: {
          applied_status: '',
          amount: '',
        },
        total_surcharge_amount: '',
        subtotal_amount: '',
        applied_discount: {
          applied_status: '',
          amount: ''
        },
        coupon_discount: {
          applied_status: '',
          validation_status: '',
          message: '',
          apply_on: '',
          amount: '',
          coupon_id: '',
          coupon_code: ''
        },

        credited_payment_discount: {
          applied_status: '',
          validation_status: '',
          amount: '',
          message: ''
        },
        total_discount_amount: '',
        gst_charge: {
          applied_status: '',
          amount: ''
        },
        grand_total: 0,

        parking_discount: {
          applied_status: '',
          amount: '',

        },
        loyalty_discount: {
          applied_status: '',
          amount: '',

        },

      },
      cardTokenBackendErrors: {},
      // cardInformation
      cardName: '',
      cardNumber: '',
      cardExpireMonth: '',
      cardExpireYear: '',
      cardExpiryDate: '',
      cardCvc: '',

      cardTokenResponse: {
        id: '',
        used: '',
        card: {},
        payment_gateway: ''
      },
      simplifyPaymentGatewayId: '',
      cardSurcharge: 0,
      eftReference: '',

      appointmentSuccessDebounce: null,

      notifyToCustomer: false,
      withPayment: false,
      isCashReceived: false,
      isLoading: false,
    }
  },

  computed: {
    ...mapGetters({
      previousRoute: 'previousRoute',
      timeIntervalOptions: 'timeIntervalOptions',
      customer: 'appCustomers/customer',
      services: 'appService/services',
      onSiteAddressDistance: 'appAppointments/onSiteAddressDistance',
      technicianAvailableTimeSlots: 'appTechnicians/technicianAvailableTimeSlots',
      availableTechnicians: 'appTechnicians/availableTechnicians',
      appointment: 'appAppointments/appointment',
      payment: 'appPayments/payment',
      authUser: 'appAuthLogin/authUser',
      settingPaymentCardSurcharge: 'appSettings/settingPaymentCardSurcharge',
      coupons: 'appCoupons/coupons',

    }),

    // states() {
    //     return this.$store.getters['appSettings/settingDefaultState'].value
    // },
    customerOptions() {
      let customers = this.$store.getters['appCustomers/customers'].map((customer) => {
        let id = customer.user.id;
        let name = customer.user.first_name + '  ' + customer.user.last_name + ' <' + customer.user.email + '> ' + customer.user.phone_number;

        return {
          value: id,
          name: name,
          customer: customer,
        };
      });
      return [...customers];
    },

    parkingTypes() {
      return this.$store.getters['appSettings/settingDefaultParkingType'].value
    },
    servicePlatforms() {
      return this.$store.getters['appSettings/settingAppointmentServicePlatform'].value
    },
    customers() {
      return this.$store.getters['appCustomers/customers']
    },

    userFullAddress() {
      let businessName = this.address.business.name;
      let addressString = this.buildAddress(this.address);
      return this.address.business.id ?
          `${businessName}` + (addressString): addressString;
    },
    serviceFullAddress() {
      let businessName = this.serviceAddress.business.name;
      let addressString = this.buildAddress(this.serviceAddress);
      return this.serviceAddress.business.id ?
          `${businessName}` + (addressString): addressString;
    },

    selectedAddressData() {
      return {
        value: this.address.id,
        name: this.userFullAddress,
      };
    },

  },

  watch: {

    cardExpiryDate(currentExpiryDate) {
      this.cardExpireMonth = currentExpiryDate.slice(0, 2);
      this.cardExpireYear = currentExpiryDate.slice(-2);
      if (currentExpiryDate.length === 5) {
        this.$refs.cvvInput.focus();
      }
    },

    async selectedSearchedCustomer() {
      if (this.selectedSearchedCustomer.customer.id) {
        await this.getCustomerInformation(this.selectedSearchedCustomer.customer.id);
      }

    },

    selectedRequestedTimeInterval(currentSelectedRequestedTimeInterval) {
      if (currentSelectedRequestedTimeInterval?.name) {
        this.selectedAppointmentDate = "";
        this.selectedAppointmentTime = "";
        this.frontEndErrors.appointmentDate = [];
        this.frontEndErrors.appointmentTime = [];

      }
    },
    serviceType(currentServiceType) {
      if (currentServiceType) {
        this.selectedAppointmentDate = "";
        this.selectedAppointmentTime = "";
        this.frontEndErrors.serviceType = [];
        this.frontEndErrors.appointmentDate = [];
        this.frontEndErrors.appointmentTime = [];

      }
    },
    appointmentType(currentAppointmentType) {
      if (currentAppointmentType) {
        this.frontEndErrors.appointmentType = [];

      }
    },
    customerType(currentCustomerType) {
      if (currentCustomerType) {
        this.frontEndErrors.customerType = [];

      }
    },
    selectedAppointmentTime(currentAppointmentTime) {
      if (currentAppointmentTime) {
        this.frontEndErrors.appointmentTime = [];

      }
    },
    selectedTechnician(currentTechnician) {
      if (currentTechnician) {
        this.frontEndErrors.appointmentTechnician = [];

      }
    },
    selectedService(currentService) {
      if (currentService) {
        this.frontEndErrors.service = [];
      }
    },

    selectedServicePlatform(currentServicePlatform) {
      if (currentServicePlatform) {
        this.frontEndErrors.servicePlatform = [];
      }
    },

    describeProblem(currentProblemNote) {
      if (currentProblemNote) {
        this.frontEndErrors.describeProblem = [];
      }
    },

    onSiteAddressDistance(currentOnSiteAddressDistance) {
      this.postOnSiteAddressDistanceResponse.message = currentOnSiteAddressDistance.message;
    },

    technicianAvailableTimeSlots(currentAvailableTimeSlots) {
      this.postTechnicianAvailableTimeSlotsResponse.slots = currentAvailableTimeSlots.slots;
      this.postTechnicianAvailableTimeSlotsResponse.status = currentAvailableTimeSlots.status;
      this.postTechnicianAvailableTimeSlotsResponse.message = currentAvailableTimeSlots.message;
    },
    availableTechnicians(currentAvailableTechnicians) {
      // this.getAvailableTechniciansResponse.technicians = currentAvailableTechnicians.technicians;
      this.getAvailableTechniciansResponse.technicians = (currentAvailableTechnicians?.technicians ?? []).map((technican) => ({
        name: `${technican.name} ( ${technican.distance} by ${technican.duration} )`,
        id: technican.id,
      }));
      // this.getAvailableTechniciansResponse.technicians = currentAvailableTechnicians.technicians;
      this.getAvailableTechniciansResponse.status = currentAvailableTechnicians.status;
      this.getAvailableTechniciansResponse.message = currentAvailableTechnicians.message;
    },

    async selectedAppointmentDate(currentDate) {
      if (currentDate) {
        this.frontEndErrors.appointmentDate = [];
        this.selectedAppointmentTime = "";
        const isTimeAvailable = await this.getTechnicianTimeSlots(currentDate);
        if (!isTimeAvailable) {
          document.querySelector('[data-target="#postTechnicianAvailableTimeSlotsResponseModal"]').click();
        }

      }
    }
  },

  methods: {
    ...mapActions({
      postTechnicianAvailableTimeSlots: 'appTechnicians/postTechnicianAvailableTimeSlots',
      getAvailableTechnicians: 'appTechnicians/getAvailableTechnicians',
      postOnSiteAddressDistance: 'appAppointments/postOnSiteAddressDistance',
      appointmentCreators: 'appAppointments/appointmentCreators',
      postAppointmentNotes: 'appAppointments/postAppointmentNotes',
      preStoreAppointmentData: 'appAppointments/preStoreAppointmentData',
      postAppointmentCharge: 'appAppointments/postAppointmentCharge',
      postAppointmentHistory: 'appAppointments/postAppointmentHistory',
      postAppointment: 'appAppointments/postAppointment',
      postAppointmentCreationNotifyEmail: 'appAppointments/postAppointmentCreationNotifyEmail',
      postAppointmentTechnician: 'appAppointments/postAppointmentTechnician',
      postAppointmentPayments: 'appAppointments/postAppointmentPayments',
      getServices: 'appService/getServices',
      getCustomers: 'appCustomers/getCustomers',
      getCustomer: 'appCustomers/getCustomer',
      getSettings: 'appSettings/getSettings',
      resetCustomers: 'appCustomers/resetCustomers',
      paymentCreate: 'appPaymentGateways/paymentCreate',
      cardTokenCreate: 'appPaymentGateways/cardTokenCreate',
      paymentCreateByToken: 'appPaymentGateways/paymentCreateByToken',
      postPayment: 'appPayments/postPayment',
      postPaymentCreationNotifyEmail: 'appPayments/postPaymentCreationNotifyEmail',
      postCardPayment: 'appCardPayments/postCardPayment',
      postCashPayment: 'appCashPayments/postCashPayment',
      postEftPayment: 'appEftPayments/postEftPayment',
      getAvailableValidCoupons: 'appCoupons/getAvailableValidCoupons',
      postDiscountStoreList: 'appDiscounts/postDiscountStoreList',
      postCouponUsage: 'appCouponUsages/postCouponUsage'

    }),

    formatExpiryDate(event) {
      if (event.length == 2) {

        this.cardExpiryDate = event + "/";
        console.log(document.getElementById('expiryDateInput').value, 'first')
      }

    },

    appointmentDetailsStepNext() {
      if (this.preAppointmentResponse.status === true) {

        this.step++;
      }
    },

    searchCustomers(event) {
      // searchCustomersDebounce
      clearTimeout(this.searchCustomersDebounce);
      this.searchCustomersDebounce = setTimeout(() => {
        if (event.target.value) {
          this.getCustomersListParams.where_has_user_query = event.target.value;
          this.getCustomers(this.getCustomersListParams);

        } else {

          this.resetCustomers();
        }
      }, 700);

    },

    /*async appointmentDateStepNext() {
        if (this.selectedAppointmentDate) {
            this.frontEndErrors.appointmentDate = [];
            const isTimeAvailable = await this.getTechnicianTimeSlots();
            this.selectedAppointmentTime = "";
            if (isTimeAvailable) {

                this.step++;
            } else {

                document.querySelector('[data-target="#postTechnicianAvailableTimeSlotsResponseModal"]').click();
            }

        }
        if (!this.selectedAppointmentDate) {
            this.frontEndErrors.appointmentDate = [];
            this.frontEndErrors.appointmentDate.push('Select a date')
        }
    },*/

    prev() {
      this.step--;
    },

    async serviceTypeStepNext() {
      if (this.serviceType && this.selectedAppointmentDate) {
        this.frontEndErrors.serviceType = [];
        this.frontEndErrors.appointmentDate = [];
        if (this.technicianAvailableTimeSlots.status === true) {
          if (this.selectedAppointmentTime) {
            this.frontEndErrors.appointmentTime = [];
            await this.appointmentTimeStepNext();

          } else {
            this.frontEndErrors.appointmentTime = [];
            this.frontEndErrors.appointmentTime.push('Select a time')
          }
        } else {
          document.querySelector('[data-target="#postTechnicianAvailableTimeSlotsResponseModal"]').click();
        }

      }

      if (!this.serviceType) {
        this.frontEndErrors.serviceType = [];
        this.frontEndErrors.serviceType.push('Please make a selection')
      }
      if (!this.selectedAppointmentDate) {
        this.frontEndErrors.appointmentDate = [];
        this.frontEndErrors.appointmentDate.push('Select a date')
      }

    },

    async findCustomer(query) {
      if (query.length > 2) {
        clearTimeout(this.searchCustomersDebounce);
        this.isLoading = true;
        this.searchCustomersDebounce = setTimeout(async () => {
          this.getCustomersListParams.where_has_user_search_query = query;
          await this.getCustomers(this.getCustomersListParams)
              .then((response) => {
                this.isLoading = !!(response && response.message && response.status !== 200);
              });
        }, 900);
      }
    },

    async customerTypeStepNext() {
      if (this.customerType) {
        this.selectedAppointmentDate = '';
        this.frontEndErrors.customerType = [];
        if (this.customerType == 'existing') {

          if (this.address.id) {
            if (this.user.status !== 'Active') {
              this.showToastMessage({
                type: 'error',
                message: 'This is an inactive customer. Please active this customer first then you can create an appointment for this customer'
              });
              return;
            }
            const isInRange = await this.distanceCalculation(this.serviceAddress.street, this.serviceAddress.suburb, this.serviceAddress.state, this.serviceAddress.postCode, this.serviceAddress.country);
            if (isInRange) {

              this.serviceType = "";
              this.selectedAppointmentDate = "";
              this.selectedAppointmentTime = "";
              this.frontEndErrors.serviceType = [];
              this.frontEndErrors.appointmentDate = [];
              this.frontEndErrors.appointmentTime = [];
              this.step++;
            }

          } else {
            this.frontEndErrors.customerSearch = [];
            this.frontEndErrors.customerSearch.push('Please search a customer');
          }

        }

      }
      if (!this.customerType) {
        this.frontEndErrors.customerType = [];
        this.frontEndErrors.customerType.push('Please make a selection')
      }
    },

    serviceStepNext() {
      const serviceName = this.selectedService.name;
      if (serviceName) {
        this.frontEndErrors.service = [];
        this.step++;
      }
      if (!serviceName) {
        this.frontEndErrors.service = [];
        this.frontEndErrors.service.push('Select a service');
      }
    },

    async distanceCalculation(street, suburb, state, post_code, country) {
      this.loader(true);
      const data = {
        street,
        suburb,
        state,
        post_code,
        country
      }
      const response = await this.postOnSiteAddressDistance(data);
      this.loader(false);
      if (response.status === 200) {

        if (this.onSiteAddressDistance.status === false) {
          document.querySelector('[data-target="#PostOnSiteAddressDistanceResponseModal"]').click();
          return false;

        } else {
          return true;

        }
      }

      if (response.message) {
        this.showToastMessage(response);
      }

      return false;

    },

    parkingTypeStepNext() {
      const parkingTypeName = this.selectedParkingType.name;

      if (parkingTypeName) {
        this.frontEndErrors.parkingType = []
        this.step++;
      }
      if (!parkingTypeName) {
        this.frontEndErrors.parkingType = [];
        this.frontEndErrors.parkingType.push('Select a parking type')
      }

    },

    async appointmentTimeStepNext() {
      if (this.selectedAppointmentTime) {

        this.selectedTechnician = '';
        const isTechniciansAvailable = await this.getTechnicians();
        if (isTechniciansAvailable) {
          this.step++;
        } else {
          document.querySelector('[data-target="#GetAvailableTechniciansResponse"]').click();

        }

      }

    },

    async technicianStepNext() {
      const selectedTechnician = this.selectedTechnician.name !== undefined ? this.selectedTechnician.name : '';
      if (selectedTechnician) {
        this.frontEndErrors.appointmentTechnician = [];

        this.step++;

      }
      if (!selectedTechnician) {
        this.frontEndErrors.appointmentTechnician = [];
        this.frontEndErrors.appointmentTechnician.push('Select a technician')
      }

    },

    servicePlatformStepNext() {
      const servicePlatformSelectedValue = this.selectedServicePlatform.value;
      if (servicePlatformSelectedValue) {
        this.frontEndErrors.servicePlatform = [];
        this.step++;
      }
      if (!servicePlatformSelectedValue) {
        this.frontEndErrors.servicePlatform = [];
        this.frontEndErrors.servicePlatform.push('Select a service platform')
      }
    },

    async describeProblemStepNext() {

      if (this.describeProblem) {

        this.frontEndErrors.describeProblem = [];
        this.couponCode = "";
        this.applyCoupon = false;
        this.applyCredit = false;
        this.creditCode = "";
        this.discount = "";
        this.discountAmount = "";
        this.paymentType = "";
        this.cardTokenBackendErrors = {};
        this.cardName = "";
        this.cardNumber = "";
        this.cardExpireMonth = "";
        this.cardExpireYear = "";
        this.cardCvc = "";
        this.cardTokenResponse.id = "";
        this.cardTokenResponse.used = "";
        this.cardTokenResponse.card = {};
        this.cardTokenResponse.payment_gateway = "";
        this.simplifyPaymentGatewayId = "";
        this.cardSurcharge = 0;
        this.eftReference = "";
        this.discount = "";
        this.discountAmount = "";
        this.paymentType = "";

        await this.preAppointment();

      }
      if (!this.describeProblem) {
        this.frontEndErrors.describeProblem = [];
        this.frontEndErrors.describeProblem.push('Describe your problem')
      }
    },

    async createNewCustomer() {
      this.$router.push({
        name: 'appCustomerCreate',
        params: {
          routeName: this.$route.name,
          // serviceType: this.serviceType == "remote" ? 1 : 0

        }
      })
    },

    async updateModalAddressData(updateModalAddressData) {
      console.log(updateModalAddressData);
      this.address.id = updateModalAddressData.addressData.id;
      this.address.subpremise = updateModalAddressData.addressData.subpremise;
      this.address.street = updateModalAddressData.addressData.street;
      this.address.suburb = updateModalAddressData.addressData.suburb;
      this.address.state = updateModalAddressData.addressData.state;
      this.address.postCode = updateModalAddressData.addressData.postCode;
      this.address.country = updateModalAddressData.addressData.country;

      if (this.serviceType === 'business') {
        this.address.business.id = updateModalAddressData.addressData.business.id;
        this.address.business.name = updateModalAddressData.addressData.business.name;
      }
    },

    async updateServiceAddressData(addressData) {
      console.log(addressData)
      this.serviceAddress.business.id = addressData.addressData.business && addressData.addressData.business.id ? addressData.addressData.business.id : null;
      this.serviceAddress.business.name = addressData.addressData.business && addressData.addressData.business.name ? addressData.addressData.business.name : null;
      this.serviceAddress.id = addressData.addressData?.id ?? '';
      this.serviceAddress.subpremise = addressData.addressData?.subpremise ?? '';
      this.serviceAddress.street = addressData.addressData?.street ?? '';
      this.serviceAddress.suburb = addressData.addressData?.suburb ?? '';
      this.serviceAddress.state = addressData.addressData?.state ?? '';
      this.serviceAddress.postCode = addressData.addressData?.postCode ?? '';
      this.serviceAddress.country = addressData.addressData?.country ?? '';

    },

    async getTechnicianTimeSlots(date) {

      // this service address
      const state = this.serviceAddress.state;
      const preference = this.serviceType == 'remote' ? 1 : 0;


      const data = {
        panel: 1,
        requested_date: date,
        state: state,
        preference: preference,
        requested_time_interval: this.selectedRequestedTimeInterval.value,
      }
      this.loader(true);
      const response = await this.postTechnicianAvailableTimeSlots(data);
      this.loader(false);
      if (response.status === 200) {

        return this.technicianAvailableTimeSlots.status === true;

      } else {
        return false;

      }

    },

    async getTechnicians() {

      const state = this.serviceAddress.state;
      const preference = this.serviceType == 'remote' ? 1 : 0;
      const requested_time_slot = this.selectedAppointmentTime.value;
      const destination_address = `${this.serviceAddress?.street ?? ''}, ${this.serviceAddress?.suburb ?? ''} ${this.serviceAddress?.state?.toLowerCase() ?? ''} ${this.serviceAddress?.post_code ?? ''}, ${this.serviceAddress?.country ?? ''}`


      const data = {
        requested_date: this.selectedAppointmentDate,
        requested_time_slot: requested_time_slot,
        state: state,
        preference: preference,
        destination_address: destination_address,
        requested_time_interval: this.selectedRequestedTimeInterval.value
      }
      this.loader(true);
      const response = await this.getAvailableTechnicians(data);
      this.loader(false);
      if (response.status === 200) {

        return this.availableTechnicians.status === true;

      } else {
        return false;

      }

    },

    async getCustomerInformation(id) {
      const params = {
        id: id,
        params: this.getCustomerParams,
      };

      this.loader(true);
      const response = await this.getCustomer(params);
      this.loader(false);

      if (response && response.status !== 200) {

        this.showToastMessage(response);

      } else {

        this.user.firstName = (this.customer.user && this.customer.user.first_name) ? this.customer.user.first_name : '';
        this.user.lastName = (this.customer.user && this.customer.user.last_name) ? this.customer.user.last_name : '';
        this.user.phoneNumber = (this.customer.user && this.customer.user.phone_number) ? this.customer.user.phone_number : '';
        this.user.email = (this.customer.user && this.customer.user.email) ? this.customer.user.email : '';
        this.user.id = (this.customer.user && this.customer.user.id) ? this.customer.user.id : '';
        this.user.status = this.customer.status ? this.customer.status : '';

        // setting the data for billing address
        this.address.subpremise = (this.customer.address && this.customer.address.subpremise) ? this.customer.address.subpremise : '';
        this.address.street = (this.customer.address && this.customer.address.street) ? this.customer.address.street : '';
        this.address.postCode = (this.customer.address && this.customer.address.post_code) ? this.customer.address.post_code : '';
        this.address.suburb = (this.customer.address && this.customer.address.suburb) ? this.customer.address.suburb : '';
        this.address.id = (this.customer.address && this.customer.address.id) ? this.customer.address.id : '';
        this.address.country = (this.customer.address && this.customer.address.country) ? this.customer.address.country : '';
        this.address.state = (this.customer.address && this.customer.address.state) ? this.customer.address.state : '';
        this.address.business.id = (this.customer.address && this.customer.address.business && this.customer.address.business.id ? this.customer.address.business.id : null)
        this.address.business.name = (this.customer.address && this.customer.address.business && this.customer.address.business.name ? this.customer.address.business.name : null);

        // customer search selection front end validation error remove.
        this.frontEndErrors.customerSearch = [];

        // let index = this.states.findIndex((state) => state.value.toLowerCase().includes((this.address.state).toLowerCase()));
        // if (index !== -1) {
        //     this.existingState = this.states[index]
        // }
        this.appointmentType = this.address.business.id ? 'business' : 'home';

        // setting the data for service address
        if (!(this.serviceAddress.id)) {
          this.serviceAddress.id = this.address.id;
          this.serviceAddress.subpremise = this.address.subpremise;
          this.serviceAddress.street = this.address.street;
          this.serviceAddress.state = this.address.state;
          this.serviceAddress.postCode = this.address.postCode;
          this.serviceAddress.suburb = this.address.suburb;
          this.serviceAddress.country = this.address.country;
          this.serviceAddress.business.id = this.address.business.id;
          this.serviceAddress.business.name = this.address.business.name;
        }

        this.customersSearchKey = this.user.firstName + " " + this.user.lastName;
        // this.serviceAddress.id = "";
        // this.serviceAddress.street = "";
        // this.serviceAddress.suburb = "";
        // this.serviceAddress.state = "";
        // this.serviceAddress.postCode = "";
        // this.serviceAddress.country = "";
        // this.serviceAddress.business.id = "";
        // this.serviceAddress.business.name = "";
        await this.resetCustomers();
      }

    },

    async applyCouponHandler() {

      if (this.preAppointmentResponse.coupon_discount.validation_status === true) {
        this.couponCode = "";
      }
      await this.preAppointment();
    },

    async applyCreditHandler() {
      await this.preAppointment();
    },

    async applyDiscountHandler() {
      await this.preAppointment();
    },

    async preAppointment() {
      let street = '';
      let suburb = '';
      let post_code = '';
      let country = '';
      let state = '';
      let user_id = '';

      street = this.serviceAddress.street;
      suburb = this.serviceAddress.suburb;
      post_code = this.serviceAddress.postCode;
      country = this.serviceAddress.country;
      state = this.serviceAddress.state;
      user_id = this.user.id;

      const data = {
        preference: this.serviceType == 'remote' ? 1 : 0,
        type: this.appointmentType === 'business' ? 1 : 0,
        street: street,
        suburb: suburb,
        post_code: post_code,
        country: country,
        state: state,
        service_id: this.selectedService.id !== undefined ? this.selectedService.id : '',
        date: this.selectedAppointmentDate,

        time: this.selectedAppointmentTime.value,
        // time: '19:00',
        user_id: user_id,

        coupon_code: this.applyCoupon ? this.couponCode : '',

        credited_payment_discount_amount: this.applyCredit ? this.creditCode * 100 : '',
        applied_discount_amount: this.discount !== 'any' ? this.discount : ((this.discountAmount) * 100),
        applied_discount_type: this.discount !== 'any' ? 0 : 1,
        parking_type: this.selectedParkingType.value !== undefined ? this.selectedParkingType.value : '',
        client_panel: 1,
        requested_time_interval: this.selectedRequestedTimeInterval.value,
      };
      this.loader(true);
      const response = await this.preStoreAppointmentData(data);
      this.loader(false);
      if (response && response.status === 200) {

        if (response.data.status === true) {

          this.preAppointmentResponse.status = response.data.status;
          this.preAppointmentResponse.message = response.data.message;

          this.preAppointmentResponse.service_price = response.data.service_price;
          this.preAppointmentResponse.service_unit_price = response.data.service_unit_price;
          this.preAppointmentResponse.service_quantity = response.data.service_quantity;

          this.preAppointmentResponse.remaining_credited_payment = response.data.remaining_credited_payment;

          this.preAppointmentResponse.same_day_surcharge.applied_status = response.data.same_day_surcharge.applied_status;
          this.preAppointmentResponse.same_day_surcharge.amount = response.data.same_day_surcharge.amount;

          this.preAppointmentResponse.timebase_surcharge.applied_status = response.data.timebase_surcharge.applied_status;
          this.preAppointmentResponse.timebase_surcharge.amount = response.data.timebase_surcharge.amount;

          this.preAppointmentResponse.distance_surcharge.applied_status = response.data.distance_surcharge.applied_status;
          this.preAppointmentResponse.distance_surcharge.distance_value = response.data.distance_surcharge.distance_value;
          this.preAppointmentResponse.distance_surcharge.distance_value_in_km = response.data.distance_surcharge.distance_value_in_km;
          this.preAppointmentResponse.distance_surcharge.amount = response.data.distance_surcharge.amount;

          this.preAppointmentResponse.holiday_surcharge.applied_status = response.data.holiday_surcharge.applied_status;
          this.preAppointmentResponse.holiday_surcharge.amount = response.data.holiday_surcharge.amount;

          this.preAppointmentResponse.fuel_levy.applied_status = response.data.fuel_levy.applied_status;
          this.preAppointmentResponse.fuel_levy.amount = response.data.fuel_levy.amount;

          this.preAppointmentResponse.city_area_surcharge.applied_status = response.data.city_area_surcharge.applied_status;
          this.preAppointmentResponse.city_area_surcharge.amount = response.data.city_area_surcharge.amount;

          this.preAppointmentResponse.weekend_surcharge.amount = response.data.weekend_surcharge.amount;
          this.preAppointmentResponse.weekend_surcharge.applied_status = response.data.weekend_surcharge.applied_status;

          this.preAppointmentResponse.total_surcharge_amount = response.data.total_surcharge_amount;
          this.preAppointmentResponse.subtotal_amount = response.data.subtotal_amount;

          this.preAppointmentResponse.applied_discount.applied_status = response.data.applied_discount.applied_status;
          this.preAppointmentResponse.applied_discount.amount = response.data.applied_discount.amount;

          this.preAppointmentResponse.parking_discount.applied_status = response.data.parking_discount.applied_status;
          this.preAppointmentResponse.parking_discount.amount = response.data.parking_discount.amount;

          this.preAppointmentResponse.loyalty_discount.applied_status = response.data.loyalty_discount.applied_status;
          this.preAppointmentResponse.loyalty_discount.amount = response.data.loyalty_discount.amount;

          this.preAppointmentResponse.coupon_discount.amount = response.data.coupon_discount.amount;
          this.preAppointmentResponse.coupon_discount.applied_status = response.data.coupon_discount.applied_status;
          this.preAppointmentResponse.coupon_discount.apply_on = response.data.coupon_discount.apply_on;
          this.preAppointmentResponse.coupon_discount.coupon_code = response.data.coupon_discount.coupon_code;
          this.preAppointmentResponse.coupon_discount.coupon_id = response.data.coupon_discount.coupon_id;
          this.preAppointmentResponse.coupon_discount.message = response.data.coupon_discount.message;
          this.preAppointmentResponse.coupon_discount.validation_status = response.data.coupon_discount.validation_status;

          this.preAppointmentResponse.credited_payment_discount.applied_status = response.data.credited_payment_discount.applied_status;
          this.preAppointmentResponse.credited_payment_discount.validation_status = response.data.credited_payment_discount.validation_status;
          this.preAppointmentResponse.credited_payment_discount.amount = response.data.credited_payment_discount.amount;
          this.preAppointmentResponse.credited_payment_discount.message = response.data.credited_payment_discount.message;

          this.preAppointmentResponse.total_discount_amount = response.data.total_discount_amount;

          this.preAppointmentResponse.gst_charge.amount = response.data.gst_charge.amount;
          this.preAppointmentResponse.gst_charge.applied_status = response.data.gst_charge.applied_status;

          this.preAppointmentResponse.grand_total = response.data.grand_total;

          if (this.step === 7) {
            this.step++;
          }

          await this.getAvailableValidCouponsHandler();

        } else {
          this.showToastMessage({
            message: response.data.message,
            type: "error"
          });

          //   document.querySelector('[data-target="#PresStoreAppointmentResponseModal"]').click();
        }

      } else {
        this.showToastMessage({
          message: response.message,
          type: "error"
        });

      }

    },

    async getSettingList(settingParams) {
      await this.getSettings(settingParams);
    },

    async getServiceList(getServiceParams) {
      await this.getServices(getServiceParams);
    },

    async createAppointment() {

      const data = {
        customer_id: this.customer.id,
        address_id: this.serviceAddress.id,
        billing_address_id: this.address.id,
        service_id: this.selectedService.id !== undefined ? this.selectedService.id : '',
        unit_price: this.preAppointmentResponse.service_unit_price,
        platform: this.selectedServicePlatform.value,
        type: this.appointmentType === 'business' ? 1 : 0,
        date: this.selectedAppointmentDate,
        time: this.selectedAppointmentTime.value,
        status: 0,
        parking: this.selectedParkingType.value,
        preference: this.serviceType == 'remote' ? 1 : 0,
        requested_time_interval: this.selectedRequestedTimeInterval.value,
        quantity: this.preAppointmentResponse.service_quantity
      };
      this.loader(true);
      const response = await this.postAppointment(data);
      this.loader(false);
      if (response.message) {
        this.showToastMessage(response);
      }
      return response.status === 200 || response.status === 201;


    },

    async appointmentCreatorsCreate() {
      const data = {
        user_id: this.$store.getters["appAuthLogin/authUser"].id,
        appointment_id: this.appointment.id,
        panel: 1
      };
      this.loader(true);
      const response = await this.appointmentCreators(data);
      this.loader(false);
      if (response.message) {
        this.showToastMessage(response);
      }

      if (response.status === 200 || response.status === 201) {

        this.showToastMessage({
          message: 'Appointment creator created',
          type: 'success'
        });
        return true;

      } else {

        return false
      }

    },

    appointmentHistory() {
      const data = {
        user_id: this.$store.getters["appAuthLogin/authUser"].id,
        appointment_id: this.appointment.id,
        technician_id: this.selectedTechnician.id !== undefined ? this.selectedTechnician.id : '',
        panel: 1,
        status: 0,
      };

      this.postAppointmentHistory(data);
    },

    appointmentEmailNotify() {
      const data = {
        appointment: this.appointment.id,
        params: {
          notify_customer: this.notifyToCustomer ? 1 : 0,
          notify_technician: 1,
          notify_internal_user: 1
        }

      };
      this.postAppointmentCreationNotifyEmail(data);
    },

    appointmentPaymentEmailNotify() {
      const data = {
        id: this.payment.id,
        params: {
          notify_customer: 1,
          notify_internal_user: 1
        }

      };
      this.postPaymentCreationNotifyEmail(data);
    },

    async createAppointmentNotes() {
      const data = {
        user_id: this.user.id, //  201,
        appointment_id: this.appointment.id,
        user_type: 0,
        type: 0,
        description: this.describeProblem,
        customer_can_view: 1
      };
      this.loader(true);
      const response = await this.postAppointmentNotes(data);
      this.loader(false);
      if (response.message) {
        this.showToastMessage(response);
      }
      return response.status === 200 || response.status === 201;

    },

    async createAppointmentTechnician() {
      const data = {
        technician_id: this.selectedTechnician.id !== undefined ? this.selectedTechnician.id : '',
        appointment_id: this.appointment.id,

      };
      this.loader(true);
      const response = await this.postAppointmentTechnician(data);
      this.loader(false);
      if (response.message) {
        this.showToastMessage(response);
      }
      if (response.status === 200 || response.status === 201) {
        this.showToastMessage({
          message: 'Appointment technician created',
          type: 'success'
        });
        return true;
      } else {

        return false
      }

    },

    async createAppointmentPayments() {
      const data = {
        payment_id: this.payment.id,
        appointment_id: this.appointment.id,
        transaction_date_time: this.payment.created_at,

      };
      this.loader(true);
      const response = await this.postAppointmentPayments(data);
      this.loader(false);
      if (response.message) {
        this.showToastMessage(response);
      }
      return response.status === 200 || response.status === 201;

    },

    async createAppointmentCharges() {
      const charges = [];
      if (this.preAppointmentResponse.gst_charge.applied_status) {
        charges.push({
          "amount": this.preAppointmentResponse.gst_charge.amount,
          "type": 3,
          "name": "GST"
        });
      }

      if (this.preAppointmentResponse.coupon_discount.applied_status) {
        charges.push({
          "amount": this.preAppointmentResponse.coupon_discount.amount,
          "type": 2,
          "name": "Coupon Discount"
        });
      }
      if (this.preAppointmentResponse.applied_discount.applied_status) {
        charges.push({
          "amount": this.preAppointmentResponse.applied_discount.amount,
          "type": 2,
          "name": "Applied Discount"
        });
      }
      if (this.preAppointmentResponse.credited_payment_discount.applied_status) {
        charges.push({
          "amount": this.preAppointmentResponse.credited_payment_discount.amount,
          "type": 2,
          "name": "Credited Payment Discount"
        });
      }
      if (this.preAppointmentResponse.parking_discount.applied_status) {
        charges.push({
          "amount": this.preAppointmentResponse.parking_discount.amount,
          "type": 2,
          "name": "Parking Discount"
        });
      }
      if (this.preAppointmentResponse.loyalty_discount.applied_status) {
        charges.push({
          "amount": this.preAppointmentResponse.loyalty_discount.amount,
          "type": 2,
          "name": "Loyalty Discount"
        });
      }

      if (this.preAppointmentResponse.distance_surcharge.applied_status && this.preAppointmentResponse.distance_surcharge.amount > 0) {
        charges.push({
          "amount": this.preAppointmentResponse.distance_surcharge.amount,
          "type": 1,
          "name": "Distance Surcharge"
        });
      }
      if (this.preAppointmentResponse.holiday_surcharge.applied_status) {
        charges.push({
          "amount": this.preAppointmentResponse.holiday_surcharge.amount,
          "type": 1,
          "name": "Holiday Surcharge"
        });
      }
      if (this.preAppointmentResponse.fuel_levy.applied_status) {
        charges.push({
          "amount": this.preAppointmentResponse.fuel_levy.amount,
          "type": 1,
          "name": "Fuel Levy Surcharge"
        });
      }
      if (this.preAppointmentResponse.city_area_surcharge.applied_status) {
        charges.push({
          "amount": this.preAppointmentResponse.city_area_surcharge.amount,
          "type": 1,
          "name": "City Area Surcharge"
        });
      }
      if (this.preAppointmentResponse.same_day_surcharge.applied_status) {
        charges.push({
          "amount": this.preAppointmentResponse.same_day_surcharge.amount,
          "type": 1,
          "name": "Same Day Surcharge"
        });
      }
      if (this.preAppointmentResponse.timebase_surcharge.applied_status) {
        charges.push({
          "amount": this.preAppointmentResponse.timebase_surcharge.amount,
          "type": 1,
          "name": "Timebase Surcharge"
        });
      }
      if (this.preAppointmentResponse.weekend_surcharge.applied_status) {
        charges.push({
          "amount": this.preAppointmentResponse.weekend_surcharge.amount,
          "type": 1,
          "name": "Weekend Surcharge"
        });
      }

      if (this.preAppointmentResponse.service_price) {
        charges.push({
          "amount": this.preAppointmentResponse.service_price,
          "type": 0,
          "name": "Service Charge"
        });
      }

      const data = {
        appointment_id: this.appointment.id,
        charges: charges
      };
      this.loader(true);
      const response = await this.postAppointmentCharge(data);
      this.loader(false);
      if (response.message) {
        this.showToastMessage(response);
      }
      if (response.status === 200 || response.status === 201) {
        this.showToastMessage({
          message: 'Appointment charge created',
          type: 'success'
        });
        return true;
      } else {
        return false;
      }

    },

    async createAppointmentHandler() {

      if (this.withPayment === true && !this.paymentType) {
        this.showToastMessage({
          type: 'error',
          message: 'Please select a payment type'
        });
        return;
      }
      this.frontEndErrors.paymentTypeSelection = [];

      if (this.paymentType === 'cardPayment' && this.withPayment) {
        this.isCashReceived = false;
        this.simplifyPaymentGatewayId = "";

        let isTokenCreated = await this.tokenCreate();

        if (isTokenCreated) {
          const isPaymentCreatedByToken = await this.createPaymentByToken();
          if (isPaymentCreatedByToken) {

            const isAppointmentCreated = await this.createAppointment();
            if (isAppointmentCreated) {
              const isAppointmentChargeCreated = await this.createAppointmentCharges();
              if (isAppointmentChargeCreated) {
                const isAppointmentCreatorCreated = await this.appointmentCreatorsCreate();
                if (isAppointmentCreatorCreated) {
                  const isAppointmentNotesCreated = await this.createAppointmentNotes();
                  if (isAppointmentNotesCreated) {
                    const isAppointmentTechCreated = await this.createAppointmentTechnician();
                    if (isAppointmentTechCreated) {
                      const isPaymentCreated = await this.createPayment();
                      if (isPaymentCreated) {
                        const isCardPaymentDone = await this.createCardPayment();
                        if (isCardPaymentDone) {
                          const isAppointmentPaymentDone = await this.createAppointmentPayments();
                          if (isAppointmentPaymentDone) {
                            this.createAppointmentDiscountStoreList();
                            this.preAppointmentResponse.coupon_discount.validation_status === true && (this.createCouponUsage());
                            this.appointmentEmailNotify();
                            this.appointmentPaymentEmailNotify();
                            this.appointmentHistory();
                            document.querySelector('[data-target="#appointmentCreateSuccessModal"]').click();
                            this.showToastMessage({
                              message: 'Appointment created',
                              type: 'success'
                            });
                            this.showToastMessage({
                              message: 'Payment successful',
                              type: 'success'
                            });
                            clearTimeout(this.appointmentSuccessDebounce);
                            this.appointmentSuccessDebounce = setTimeout(async () => {
                              await this.closeAppointmentSuccessModal();
                            }, 10000);

                          }

                        }

                      }
                    }

                  }
                }
              }

            }

          }

        }

      } else if (this.paymentType === 'cashPayment' && this.withPayment) {
        if (this.isCashReceived) {
          const isAppointmentCreated = await this.createAppointment();
          if (isAppointmentCreated) {
            const isAppointmentChargeCreated = await this.createAppointmentCharges();
            if (isAppointmentChargeCreated) {
              const isAppointmentCreatorCreated = await this.appointmentCreatorsCreate();
              if (isAppointmentCreatorCreated) {
                const isAppointmentNotesCreated = await this.createAppointmentNotes();
                if (isAppointmentNotesCreated) {
                  const isAppointmentTechCreated = await this.createAppointmentTechnician();

                  if (isAppointmentTechCreated) {
                    const isPaymentCreated = await this.createPayment();
                    if (isPaymentCreated) {
                      const isCashPaymentDone = await this.createCashPayment();
                      if (isCashPaymentDone) {

                        const isAppointmentPaymentDone = await this.createAppointmentPayments();
                        if (isAppointmentPaymentDone) {
                          this.createAppointmentDiscountStoreList();
                          this.preAppointmentResponse.coupon_discount.validation_status === true && (this.createCouponUsage());
                          this.appointmentEmailNotify();
                          this.appointmentPaymentEmailNotify();
                          this.appointmentHistory();
                          this.showToastMessage({
                            message: 'Appointment created',
                            type: 'success'
                          });
                          document.querySelector('[data-target="#appointmentCreateSuccessModal"]').click();
                          clearTimeout(this.appointmentSuccessDebounce);
                          this.appointmentSuccessDebounce = setTimeout(async () => {
                            await this.closeAppointmentSuccessModal();
                          }, 10000);

                        }
                      }

                    }
                  }

                }
              }
            }

          }
          return;

        }
        this.showToastMessage({
          type: 'error',
          message: 'If you received the payment in cash then mark on cash received otherwise create appointment without payment'
        });

      } else if (this.paymentType === 'eftPayment' && this.withPayment) {
        this.isCashReceived = false;
        // eft payment postEftPayment
        if (this.eftReference.length >= 2) {
          this.frontEndErrors.eftReference = [];
          const isAppointmentCreated = await this.createAppointment();
          if (isAppointmentCreated) {
            const isAppointmentChargeCreated = await this.createAppointmentCharges();
            if (isAppointmentChargeCreated) {
              const isAppointmentCreatorCreated = await this.appointmentCreatorsCreate();
              if (isAppointmentCreatorCreated) {
                const isAppointmentNotesCreated = await this.createAppointmentNotes();
                if (isAppointmentNotesCreated) {

                  if (isAppointmentNotesCreated) {
                    const isAppointmentTechCreated = await this.createAppointmentTechnician();
                    if (isAppointmentTechCreated) {
                      const isPaymentCreated = await this.createPayment();
                      if (isPaymentCreated) {
                        const isEftPaymentDone = await this.createEftPayment();
                        if (isEftPaymentDone) {

                          const isAppointmentPaymentDone = await this.createAppointmentPayments();
                          if (isAppointmentPaymentDone) {
                            this.createAppointmentDiscountStoreList();
                            this.preAppointmentResponse.coupon_discount.validation_status === true && (this.createCouponUsage());
                            this.appointmentEmailNotify();
                            this.appointmentPaymentEmailNotify();
                            this.appointmentHistory();
                            this.showToastMessage({
                              message: 'Appointment created',
                              type: 'success'
                            });
                            document.querySelector('[data-target="#appointmentCreateSuccessModal"]').click();
                            clearTimeout(this.appointmentSuccessDebounce);
                            this.appointmentSuccessDebounce = setTimeout(async () => {
                              await this.closeAppointmentSuccessModal();
                            }, 10000);

                          }
                        }

                      }
                    }

                  }

                }
              }
            }

          }
        } else {
          this.frontEndErrors.eftReference = [];
          this.frontEndErrors.eftReference.push('Please enter reference at least two character');
        }

      } else {
        // without payment
        const isAppointmentCreated = await this.createAppointment();
        if (isAppointmentCreated) {
          const isAppointmentChargeCreated = await this.createAppointmentCharges();
          if (isAppointmentChargeCreated) {
            const isAppointmentCreatorCreated = await this.appointmentCreatorsCreate();
            if (isAppointmentCreatorCreated) {
              const isAppointmentNotesCreated = await this.createAppointmentNotes();
              if (isAppointmentNotesCreated) {
                const isAppointmentTechCreated = await this.createAppointmentTechnician();

                if (isAppointmentTechCreated) {

                  this.createAppointmentDiscountStoreList();
                  this.preAppointmentResponse.coupon_discount.validation_status === true && (this.createCouponUsage());
                  this.appointmentEmailNotify();
                  this.appointmentHistory();
                  this.showToastMessage({
                    message: 'Appointment created',
                    type: 'success'
                  });
                  document.querySelector('[data-target="#appointmentCreateSuccessModal"]').click();
                  clearTimeout(this.appointmentSuccessDebounce);
                  this.appointmentSuccessDebounce = setTimeout(async () => {
                    await this.closeAppointmentSuccessModal();
                  }, 10000);

                }

              }
            }
          }

        }

      }

    },

    createAppointmentDiscountStoreList() {
      /*
        0:Credited Payment,
        1:Applied on Subtotal,
        2:Applied on Shipping Fee,
        3:Coupon on Subtotal,
        4:Coupon on Shipping Fee,
        5:Parking,
        6:Online Appointment
        7:Loyalty Discount
        */
      let discountsArray = [];

      if (this.preAppointmentResponse.coupon_discount.applied_status) {
        discountsArray.push({
          "amount": this.preAppointmentResponse.coupon_discount.amount,
          "type": 3,
        });
      }
      if (this.preAppointmentResponse.applied_discount.applied_status) {
        discountsArray.push({
          "amount": this.preAppointmentResponse.applied_discount.amount,
          "type": 1,

        });
      }
      if (this.preAppointmentResponse.credited_payment_discount.applied_status) {
        discountsArray.push({
          "amount": this.preAppointmentResponse.credited_payment_discount.amount,
          "type": 0,

        });
      }
      if (this.preAppointmentResponse.parking_discount.applied_status) {
        discountsArray.push({
          "amount": this.preAppointmentResponse.parking_discount.amount,
          "type": 5,
        });
      }
      if (this.preAppointmentResponse.loyalty_discount.applied_status) {
        discountsArray.push({
          "amount": this.preAppointmentResponse.loyalty_discount.amount,
          "type": 7,
        });
      }
      if (discountsArray.length === 0) {
        return;
      }
      const dataObj = {
        user_id: this.user.id,
        reference: this.appointment.reference,
        discounts: discountsArray
      }
      this.postDiscountStoreList(dataObj);
    },

    async paymentGateway() {
      const data = {
        currency: 'AUD',
        reference: '',
        amount: '',
        description: '',
        card_name: '',
        card_number: '',
        card_expire_month: '',
        card_expire_year: '',

      }
      await this.paymentCreate(data)
    },

    async tokenCreate() {

      const data = {
        card_name: this.cardName,
        card_number: this.cardNumber,
        card_expire_month: this.cardExpireMonth,
        card_expire_year: this.cardExpireYear,
        card_cvc: this.cardCvc,
      }
      this.loader(true);
      const response = await this.cardTokenCreate(data);
      this.loader(false);
      if (response.status === 200 || response.status === 201) {

        this.cardTokenBackendErrors = {};
        this.cardTokenResponse.id = response.data.id;
        this.cardTokenResponse.used = response.data.used;
        this.cardTokenResponse.card = response.data.card;
        this.cardTokenResponse.payment_gateway = response.data.payment_gateway;
        if (response.data.used === false) {
          let cardType = this.cardTokenResponse.card.type;
          if (this.settingPaymentCardSurcharge.value.status) {
            this.settingPaymentCardSurcharge.value.details.map((item) => {
              if (cardType.toLowerCase().trim() === item.name.toLowerCase().trim()) {
                this.cardSurcharge = parseInt(parseFloat(this.preAppointmentResponse.grand_total) * (parseFloat(item.rate) / 100));
              }
            })
          }

          return true;
        }

      } else {

        this.cardTokenResponse.id = '';
        this.cardTokenResponse.used = '';
        this.cardTokenResponse.card = {};
        this.cardTokenResponse.payment_gateway = '';
        this.cardTokenBackendErrors = response.errors;

      }

      if (response.message) {
        this.showToastMessage(response);
      }
      return false;

    },

    async createPaymentByToken() {

      const data = {
        currency: 'AUD',
        reference: 'Admin Appointment payment',
        amount: this.preAppointmentResponse.grand_total + (this.cardSurcharge),
        description: `Admin paid $ ${this.preAppointmentResponse.grand_total + (this.cardSurcharge)} from back-office admin panel(Appointment)`,
        card_token: this.cardTokenResponse.id,
      }
      this.loader(true);
      const response = await this.paymentCreateByToken(data);
      this.loader(false);
      if (response.status === 200 || response.status === 201) {
        this.simplifyPaymentGatewayId = response.data.id;

        if (response.data.payment_status === 'APPROVED') {

          return true;
        }

      }
      if (response.message) {
        this.showToastMessage(response);
      }
      this.showToastMessage({
        type: 'error',
        message: 'Card Declined'
      });
      return false;

    },

    async createPayment() {
      const data = {
        // reference: this.simplifyPaymentGatewayId,
        reference: this.appointment.reference,
        panel: 1
      }
      this.loader(true);
      const response = await this.postPayment(data);
      this.loader(false);
      if (response.message) {
        this.showToastMessage(response);
      }
      return response.status === 200 || response.status === 201;

    },

    // card payment api call
    async createCardPayment() {
      const data = {
        // payment_id: this.paymentId,
        payment_id: this.payment.id,
        paid_by: this.user.id,
        amount: this.preAppointmentResponse.grand_total,
        card_type: this.cardTokenResponse.card.type,
        card_surcharge: (this.cardSurcharge),
        payment_gateway: this.cardTokenResponse.payment_gateway,
        payment_gateway_id: this.simplifyPaymentGatewayId,
        status: 1

      }
      this.loader(true);
      const response = await this.postCardPayment(data);
      this.loader(false);
      if (response.message) {
        this.showToastMessage(response);
      }
      return response.status === 200 || response.status === 201;

    },

    // cash payment api call
    async createCashPayment() {
      const data = {
        // payment_id: this.paymentId,
        payment_id: this.payment.id,
        paid_by: this.user.id,
        paid_to: this.authUser.id,
        handover_date: '',
        amount: this.preAppointmentResponse.grand_total,

      }
      this.loader(true);
      const response = await this.postCashPayment(data);
      this.loader(false);
      if (response.message) {
        this.showToastMessage(response);
      }
      return response.status === 200 || response.status === 201;

    },
    // eft payment api call
    async createEftPayment() {
      const data = {
        payment_id: this.payment.id,
        paid_by: this.user.id,
        payment_reference: this.eftReference,
        amount: this.preAppointmentResponse.grand_total,

      }
      this.loader(true);
      const response = await this.postEftPayment(data);
      this.loader(false);

      if (response.message) {
        this.showToastMessage(response);
      }
      return response.status === 200 || response.status === 201;

    },

    closeAppointmentSuccessModal() {
      clearTimeout(this.appointmentSuccessDebounce);
      document.querySelector('[data-target="#appointmentCreateSuccessModal"]').click();
      if (this.can('appointment-view-any')) {
        this.$router.push({
          name: 'appAppointmentList'
        })
        return;
      }

      this.$router.go(this.$route.name);
    },

    applyCouponEmitHandler(response) {
      document.querySelector('[data-target="#AvailableCouponsModal"]').click();
      this.applyCoupon = true;
      this.couponCode = response.couponCode;

      this.preAppointment();
    },

    async getAvailableValidCouponsHandler() {

      const data = {
        activation_amount: Number(this.preAppointmentResponse.subtotal_amount),
        user_id: this.user.id,
        apply_on: [0, 2],
        internal_coupon: null,
        website_visibility: null,
        post_code: this.serviceAddress.postCode,
      }
      this.loader(true);
      const response = await this.getAvailableValidCoupons(data);
      this.loader(false);
      if (response.message) {
        this.showToastMessage(response);
      }


    },

    createCouponUsage() {
      const data = {
        coupon_id: this.preAppointmentResponse.coupon_discount.coupon_id,
        user_id: this.user.id,
        reference: this.appointment.reference,
        discount_amount: this.preAppointmentResponse.coupon_discount.amount,
      }
      const response = this.postCouponUsage(data);
      if (response.message) {
        this.showToastMessage(response);
      }

    },
  },

  mounted() {

    this.getSettingList(this.getSettingsParams);
    this.getServiceList(this.getServiceParams);

    if (this.$props.customerId) {
      this.customerType = 'existing';
      this.getCustomerInformation(this.$props.customerId)
    }

  },
}
</script>

<style scoped>
@import './AppointmentCreate.css';

.max-value {
  display: none;
}

.appointment-info-icon:hover + .max-value {
  display: inline-block;
}
</style>
