<template>
    <div class="modal fade " id="CardSurchargeDetailsModal" tabindex="-1" role="dialog" aria-labelledby="CardSurchargeDetailsModal" aria-hidden="true">
        <div class="modal-dialog modal-dialog-scrollable modal-md modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header bg-primary">
                    <h5 class="text-white mb-0 pb-0">Card Surcharge Details </h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <i class="bx bx-x"></i>
                    </button>
                </div>
                <div class="modal-body">
                    <div v-for="surCharge in paymentCardSurcharge" :key="surCharge" class="row" >
                        <div class="col-7 text-left">
                            <p class="text-bold-400">{{surCharge.name}}:</p>
                        </div>
                        <div class="col-5 text-right">
                            <p>${{ parseFloat(surCharge.rate ).toFixed(2) }} % </p>
                        </div>
                    </div>
                    

                </div>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "CardSurchargeDetailsModal",
    props: ['settingPaymentCardSurcharge'],
    data() {
        return {

          paymentCardSurcharge:[]
        }
    },
    watch: {
        settingPaymentCardSurcharge(surCharges) {
          

            this.paymentCardSurcharge = surCharges

        }
    },

}
</script>

<style>

</style>
